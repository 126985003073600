import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import selectors from '../../selectors';
import actions from '../../actions';

import auth from '../../utils/auth';
import AdminMenuContainer from './menu';

import Companies from './companies';
import AlertHistory from './alert-history';
import Alerts from './alerts';
import Alert from '../user/alert';
import Contacts from './contacts';
import Contact from '../user/contact';
import styles from '../../styles/user';

class adminContainer extends React.Component {
    componentWillMount() {
        document.body.className = "userBody";

        const { companies, readyToFetchCompaniesAgain, fetchAllCompanies, isFetchingCompanies } = this.props;
        if ((!companies || readyToFetchCompaniesAgain) && !isFetchingCompanies) {
            fetchAllCompanies();
        }
    }
    render() {
        const { page, history } = this.props;
        return (
            <div className={styles.main.container}>
                <AdminMenuContainer page={page} history={history} admin={true} />
                <Switch>
                    <Route path="/admin/:companyId/alert-history" render={(props) => {
                        const companyId = props.match && props.match.params && props.match.params.companyId;
                        let Page = auth.admin(AlertHistory);
                        return <Page companyId={companyId ? atob(companyId) : null}  history={history} admin={true} />
                    }} />
                    <Route path="/admin/:companyId/alert/:alertId?" render={(props) => {
                        const companyId = props.match && props.match.params && props.match.params.companyId;
                        const alertId = props.match && props.match.params && props.match.params.alertId;
                        let Page = auth.admin(Alert);
                        return <Page companyId={companyId ? atob(companyId) : null} alertId={alertId ? atob(alertId) : null} history={history} admin={true} />
                    }} />
                    <Route path="/admin/:companyId/contact/:contactId?" render={(props) => {
                        const companyId = props.match && props.match.params && props.match.params.companyId;
                        const contactId = props.match && props.match.params && props.match.params.contactId;
                        let Page = auth.admin(Contact);
                        return <Page companyId={companyId ? atob(companyId) : null} contactId={contactId ? atob(contactId) : null} history={history} admin={true} />
                    }} />
                    <Route path="/admin/:companyId/contacts" render={(props) => {
                        const companyId = props.match && props.match.params && props.match.params.companyId;
                        let Page = auth.admin(Contacts);
                        return <Page companyId={companyId ? atob(companyId) : null} history={history} admin={true} />
                    }} />
                    <Route path="/admin/:companyId/alerts" render={(props) => {
                        const companyId = props.match && props.match.params && props.match.params.companyId;
                        let Page = auth.admin(Alerts);
                        return <Page companyId={companyId ? atob(companyId) : null} history={history} admin={true} />
                    }} />
                    <Route path="/admin/alert-history" render={(props) => {
                        let Page = auth.admin(AlertHistory);
                        return <Page history={history} admin={true} />
                    }} />
                    <Route path="/admin/alerts" render={(props) => {
                        let Page = auth.admin(Alerts);
                        return <Page history={history} admin={true} />
                    }} />
                    <Route path="/admin/alert" render={(props) => {
                        let Page = auth.admin(Alert);
                        return <Page history={history} admin={true} />
                    }} />
                    <Route path="/admin/contacts" render={(props) => {
                        let Page = auth.admin(Contacts);
                        return <Page history={history} admin={true} />
                    }} />
                    <Route path="/admin/contact" render={(props) => {
                        let Page = auth.admin(Contact);
                        return <Page history={history} admin={true} />
                    }} />
                    <Route path="/admin/companies" component={auth.admin(Companies)} />

                    {/* Redirect all 404's to the dashboard page */}
                    <Redirect to="/admin/companies" />
                </Switch>
            </div>
        );
    }
}

adminContainer.propTypes = { }


const mapStateToProps = (state, ownProps) => {
    return {
        companies: selectors.companies.getAll(state),
        isFetchingCompanies: selectors.companies.isFetchingAll(state),
        readyToFetchCompaniesAgain: false,
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchAllCompanies: actions.companies.fetchAll,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(adminContainer);