import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import createHistory from 'history/createBrowserHistory';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import { sagas } from './sagas';

import api from './api'

import App from './containers';

const history = createHistory();
const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        router: routerReducer,
        ...reducers
    }),
    composeEnhancers(middleware)
);

sagaMiddleware.run(sagas(api));

// Remove local storage if it does not match our definitions
const definedApi = localStorage.getItem('API_HOST');
if (![process.env.GLOBAL_API_PATH, process.env.AUS_API_PATH].includes(definedApi)) {
    localStorage.clear();
}


// setTimeout here to load the .less styles in dev
setTimeout(() => {
    ReactDOM.render(
        <Provider store={store}>
            <App history={history} />
        </Provider>,
        document.getElementById('root')
    )
}, 5);