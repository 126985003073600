exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".dashboard__dashboardItem___2gMmD {\n  vertical-align: top;\n  padding: 12px;\n}\n.dashboard__dashboardItem___2gMmD .dashboard__dashboardItemTitle___ByjQB {\n  font-weight: 500;\n  font-size: 1.2em;\n  display: block;\n  margin: 10px 0;\n  line-height: 30px;\n}\n.dashboard__dashboardItem___2gMmD .dashboard__dashboardItemTitle___ByjQB > a {\n  font-size: 0.5em;\n  position: relative;\n  display: inline-block;\n  line-height: 30px;\n  vertical-align: top;\n}\n", "", {"version":3,"sources":["/Users/matt/Desktop/matthewrothery/stormalerts/devtools/deploy/build/webapp/src/styles/user/dashboard.less"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,cAAc;CACf;AACD;EACE,iBAAiB;EACjB,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,kBAAkB;CACnB;AACD;EACE,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,oBAAoB;CACrB","file":"dashboard.less","sourcesContent":[".dashboardItem {\n  vertical-align: top;\n  padding: 12px;\n}\n.dashboardItem .dashboardItemTitle {\n  font-weight: 500;\n  font-size: 1.2em;\n  display: block;\n  margin: 10px 0;\n  line-height: 30px;\n}\n.dashboardItem .dashboardItemTitle > a {\n  font-size: 0.5em;\n  position: relative;\n  display: inline-block;\n  line-height: 30px;\n  vertical-align: top;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"dashboardItem": "dashboard__dashboardItem___2gMmD",
	"dashboardItemTitle": "dashboard__dashboardItemTitle___ByjQB"
};