import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import scheduleString from '../../utils/schedule-string';
import getSortIcon from '../../utils/sort-icon';

const contactsComponent = (props) => {
    const { 
        isFetchingContacts, contacts,
        isFetchingAlerts, alerts,
        history, canAddAlert, canAddContact,
        sort, sortBy, sortAsc, sortedContacts,
        admin, companyId
    } = props;

    const isFetching = isFetchingContacts || isFetchingContacts === null || !contacts ||
                       isFetchingAlerts || isFetchingAlerts === null || !alerts;
    
    return (
        <div className={styles.main.content}>
            <div className={styles.main.header}>
                <div className={styles.main.actions}>
                    {canAddAlert ? 
                        <Link to={`${admin ? `/admin/${btoa(companyId)}` : ''}/alert`} className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span> Add Alert</Link>
                    : 
                        <span className={`button ${styles.main.actionsAction} disabledButton`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span>Alert limit reached</span>
                    }
                    {canAddContact ? 
                        <Link to={`${admin ? `/admin/${btoa(companyId)}` : ''}/contact`} className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faUserAlt} /></span> Add Contact</Link>
                    : 
                        <span className={`button ${styles.main.actionsAction} disabledButton`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span>Contact limit reached</span>
                    }
                </div>
                <div className={styles.main.title}>Contacts</div>
                <div className={styles.main.subTitle}>system contacts</div>
            </div>

            <div className={styles.main.body}>

                {isFetching ?
                    <p>fetching contacts..</p>
                :
                    <div className="table">
                        <div className="tableHeader">
                            <div className="tableCell" onClick={() => sort('name')}>Name <FontAwesomeIcon icon={getSortIcon('name', sortBy, sortAsc)} /></div>
                            <div className="tableCell" onClick={() => sort('email')}>Email <FontAwesomeIcon icon={getSortIcon('email', sortBy, sortAsc)} /></div>
                            <div className="tableCell" onClick={() => sort('mobile')}>Mobile <FontAwesomeIcon icon={getSortIcon('mobile', sortBy, sortAsc)} /></div>
                            <div className="tableCell">Schedule</div>
                            <div className="tableCell">Alerts</div>
                        </div>
                        {sortedContacts.length > 0 ?
                            sortedContacts.map((contact, i) => {
                                const contactAlerts = Object.keys(alerts).filter(a => alerts[a].contactId === contact.id).length;
                                return (
                                    <div key={i} className="tableRow" onClick={() => { history.push(`${admin ? `/admin/${btoa(companyId)}` : ''}/contact/${btoa(contact.id)}`); }} title="Click to edit contact">
                                        <div className="tableCell">{contact.name}</div>
                                        <div className="tableCell">{contact.email}</div>
                                        <div className="tableCell">{contact.mobile}</div>
                                        <div className="tableCell">
                                            {!contact.enabled ? 'Disabled' : scheduleString(contact.schedule)}
                                        </div>
                                        <div className="tableCell">{contactAlerts}</div>
                                    </div>
                                )
                            })
                        :
                            <div className="tableRow" onClick={() => { history.push(`${admin ? `/admin/${btoa(companyId)}` : ''}/contact`); }} title="Click to create a contact">
                                <div className="tableCell">No configured contacts. <Link to={`${admin ? `/admin/${btoa(companyId)}` : ''}/contact`}>Create a contact</Link></div>
                                <div className="tableCell"></div>
                                <div className="tableCell"></div>
                                <div className="tableCell"></div>
                                <div className="tableCell"></div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default contactsComponent;