import { call, put, all, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import actions from '../actions';
import constants from '../constants';
const type = constants.actions.alerts;


const fetchAll = (api) => function* ({ }) {
    const result = yield api.alerts.fetchAll();
    if (result.status === 'success') {
        yield put(actions.alerts.fetchAllSuccess(result.data));
    } else {
        yield put(actions.alerts.fetchAllFailed(result.message));
    }
}

const fetchAllCompany = (api) => function* ({ companyId }) {
    const result =  yield api.companies.fetchAlerts(companyId);
    if (result.status === 'success') {
        return yield put(actions.alerts.fetchAllCompanySuccess(result.data, companyId));
    }
    yield put(actions.alerts.fetchAllCompanyFailed(result.message));
}

const fetch = (api) => function* ({ }) {
    const result = yield api.alerts.fetch();
    if (result.status === 'success') {
        yield put(actions.alerts.fetchSuccess(result.data));
    } else {
        yield put(actions.alerts.fetchFailed(result.message));
    }
}

const create = (api) => function* ({ data }) {
    const result = yield api.alerts.create(data);
    if (result.status === 'success') {
        // Successfully created the alert
        // add the returned alert to the state
        yield put(actions.alerts.createSuccess({ ...data, ...result.data }));

        // re-fetch the alerts & Redirect to the alert list
        if (data.companyId) {
            yield put(push(`/admin/${btoa(data.companyId)}/alerts`));
            yield put(actions.alerts.fetchAllCompany(data.companyId));
        } else {
            yield put(actions.alerts.fetchAll());
            yield put(push('/alerts'));
        }
    } else {
        yield put(actions.alerts.createFailed(result.message));
    }
}

const update = (api) => function* ({ alertId, data, companyId }) {
    const result = yield api.alerts.update(alertId, data);
    if (result.status === 'success') {
        // Successfully updated the alert
        yield put(actions.alerts.updateSuccess(alertId, data, companyId));
    } else {
        yield put(actions.alerts.updateFailed(result.message));
    }
}

const deleteAlert = (api) => function* ({ alertId, companyId}) {
    const result = yield api.alerts.delete(alertId);
    if (result.status === 'success') {
        // Successfully deleted the alert
        yield put(actions.alerts.deleteSuccess(alertId, companyId));

        if (companyId) {
            yield put(push(`/admin/${btoa(companyId)}/alerts`));
        } else {
            yield put(push('/alerts'));
        }
    } else {
        yield put(actions.alerts.deleteFailed(result.message));
    }
}




export default function* registerSagas(api) {
    yield fork(takeLatest, type.FETCH_ALL, fetchAll(api));
    yield fork(takeLatest, type.FETCH, fetch(api));
    yield fork(takeLatest, type.CREATE, create(api));
    yield fork(takeLatest, type.UPDATE, update(api));
    yield fork(takeLatest, type.DELETE, deleteAlert(api));
    yield fork(takeLatest, type.FETCH_ALL_COMPANY, fetchAllCompany(api));
    
}