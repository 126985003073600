import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUserAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import scheduleString from '../../utils/schedule-string';
import getSortIcon from '../../utils/sort-icon';

const alertsComponent = (props) => {
    const { 
        isFetchingAlerts, alerts,
        isFetchingContacts, contacts,
        isFetchingRegions, regions, 
        history, canAddAlert, canAddContact,
        sort, sortBy, sortAsc, sortedAlerts,
        admin, companyId, company
    } = props;

    const isFetching = isFetchingAlerts || isFetchingAlerts === null || !alerts || 
                       isFetchingContacts || isFetchingContacts === null || !contacts ||
                       isFetchingRegions || isFetchingRegions === null || !regions;

    return (
        <div className={styles.main.content}>
            <div className={styles.main.header}>
                <div className={styles.main.actions}>
                    {company ? <Link to={`${company ? `/admin/${btoa(company.id)}` : ''}/contacts`} className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faUsers} /></span> Manage Contacts</Link> : null}
                    {canAddAlert ? 
                        <Link to={`${company ? `/admin/${btoa(company.id)}` : ''}/alert`} className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span> Add Alert</Link>
                    : 
                        <span className={`button ${styles.main.actionsAction} disabledButton`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span>Alert limit reached</span>
                    }
                    {canAddContact ? 
                        <Link to={`${company ? `/admin/${btoa(company.id)}` : ''}/contact`} className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faUserAlt} /></span> Add Contact</Link>
                    : 
                        <span className={`button ${styles.main.actionsAction} disabledButton`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span>Contact limit reached</span>
                    }
                </div>
                <div className={styles.main.title}>{company ? `${company.name} ` : null} Alerts</div>
                <div className={styles.main.subTitle}>configured alerts</div>
            </div>

            {isFetching ?
                <p>fetching alerts..</p>
            :
                <div className="table">
                    <div className="tableHeader">
                        <div className="tableCell" onClick={() => sort('contactName')}>Contact <FontAwesomeIcon icon={getSortIcon('contactName', sortBy, sortAsc)} /></div>
                        <div className="tableCell" onClick={() => sort('companyName')}>Company <FontAwesomeIcon icon={getSortIcon('companyName', sortBy, sortAsc)} /></div>
                        <div className="tableCell" onClick={() => sort('regionName')}>Region <FontAwesomeIcon icon={getSortIcon('regionName', sortBy, sortAsc)} /></div>
                        <div className="tableCell">Schedule</div>
                    </div>
                    {sortedAlerts.length > 0 ?
                        sortedAlerts.map((alert, i) => {
                            const contact = contacts[alert.contactId] || {};
                            return (
                                <div key={i} className="tableRow" onClick={() => { history.push(`${admin ? `/admin/${btoa(contact.companyId)}` : ''}/alert/${btoa(alert.id)}`); }} title="Click to edit alert">
                                    <div className="tableCell"><Link to={`${admin ? `/admin/${btoa(contact.companyId)}` : ''}/contact/${btoa(contact.id)}`} onClick={(evt) => evt.stopPropagation()} title="Click to edit contact">{contact.name}</Link></div>
                                    <div className="tableCell">{alert.companyName}</div>
                                    <div className="tableCell">{alert.regionName}</div>
                                    <div className="tableCell">
                                        {scheduleString(contact.schedule)}
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className="tableRow" onClick={() => { history.push(`${admin ? `/admin/${btoa(companyId)}` : ''}/alert`); }} title="Click to create an alert">
                            <div className="tableCell">No configured alerts. <Link to={`${admin ? `/admin/${btoa(companyId)}` : ''}/alert`}>Create an alert</Link></div>
                            <div className="tableCell"></div>
                            <div className="tableCell"></div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default alertsComponent;