import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import autobind from 'class-autobind';
import actions from '../../actions';
import selectors from '../../selectors';
import AdminAlerts from '../../components/admin/alerts';
import sortObject from '../../utils/sort-object';

class alertsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: 'contact',
            sortAsc: {
                'contactName': true,
                'regionName': null,
                'companyName': null,
            },
            sortedAlerts: []
        }
        autobind(this);
    }
    
    componentWillMount() {
        const {
            isFetchingAlerts, alerts, fetchAlerts,
            isFetchingContacts, contacts, fetchContacts,
            isFetchingRegions, regions, fetchRegions,
            readyToFetchAlertsAgain, readyToFetchContactsAgain, readyToFetchRegionsAgain,
            companyId, 
            fetchCompanyAlerts, fetchCompanyContacts, fetchCompanyRegions
        } = this.props;

        // fetch alerts
        if ((!alerts || readyToFetchAlertsAgain) && !isFetchingAlerts) {
            if (companyId) {
                fetchCompanyAlerts(companyId);
            } else {
                fetchAlerts();
            }
        }

        // // fetch contacts
        if ((!contacts || readyToFetchContactsAgain) && !isFetchingContacts) {
            if (companyId) {
                fetchCompanyContacts(companyId);
            } else {
                fetchContacts();
            }
        }

        // // fetch regions
        if ((!regions || readyToFetchRegionsAgain) && !isFetchingRegions) {
            if (companyId) {
                fetchCompanyRegions(companyId);
            } else {
                fetchRegions();
            }
        }
    }
    
    componentDidMount() {
        this.sort('contactName');
    }

    // sort the contacts
    sort(sortBy) {
        const { alerts, contacts, regions, companies } = this.props;

        // Sort the alerts 
        let sortedAlerts = alerts && contacts && regions ? sortObject(Object.values(alerts).reduce((acc, alert) => {
            if (!contacts[alert.contactId]) { return acc; }
            return [
                ...acc,
                {
                    ...alert,
                    contactName: contacts[alert.contactId].name,
                    regionName: (regions[alert.regionId] || {}).name || '',
                    companyName: (companies[(contacts[alert.contactId] || {}).companyId] || {}).name
                }
            ]
        }, []), sortBy, this.state.sortAsc[sortBy] ? 'ASC' : 'DESC') : []

        this.setState({
            sortBy,
            sortAsc: { ...this.state.sortAsc, [sortBy]: !this.state.sortAsc[sortBy] },
            sortedAlerts
        });
    }
    
    render() {
        return (
            <AdminAlerts {...this.props} sort={this.sort} sortBy={this.state.sortBy} sortAsc={this.state.sortAsc} sortedAlerts={this.state.sortedAlerts} />
        );
    }
}

alertsContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    let companyId = ownProps.companyId;
    let alerts = companyId ? selectors.alerts.getAllForCompany(state)(companyId) : selectors.alerts.getAll(state);
    let contacts = companyId ? selectors.contacts.getAllForCompany(state)(companyId) : selectors.contacts.getAll(state);
    let regions = companyId ? selectors.regions.getAllForCompany(state)(companyId) : selectors.regions.getAll(state);
    let company = companyId ? selectors.companies.getById(state)(companyId) : null;
    let companies = selectors.companies.getAll(state);

    return {
        company,
        companies,
        rawCompanyId: companyId,
        companyId,
        alerts,
        isFetchingAlerts: companyId ? selectors.alerts.isFetchingCompany(state)(companyId) : selectors.alerts.isFetchingAll(state),

        contacts,
        isFetchingContacts: companyId ? selectors.contacts.isFetchingCompany(state)(companyId) : selectors.contacts.isFetchingAll(state),

        regions,
        isFetchingRegions: companyId ? selectors.regions.isFetchingCompany(state)(companyId) : selectors.regions.isFetchingAll(state),

        readyToFetchAlertsAgain: companyId ? selectors.alerts.readyToFetchCompanyAgain(state)(companyId) : selectors.alerts.readyToFetchAgain(state),
        readyToFetchContactsAgain: companyId ? selectors.contacts.readyToFetchCompanyAgain(state)(companyId) : selectors.contacts.readyToFetchAgain(state),
        readyToFetchRegionsAgain: companyId ? selectors.regions.readyToFetchCompanyAgain(state)(companyId) : selectors.regions.readyToFetchAgain(state),

        canAddAlert: selectors.user.canAddAlert(state, Object.keys(alerts || {}).length),
        canAddContact: selectors.user.canAddContact(state, Object.keys(contacts || {}).length),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchAlerts: actions.alerts.fetchAll,
        fetchContacts: actions.contacts.fetchAll,
        fetchRegions: actions.regions.fetchAll,

        fetchCompanyContacts: actions.contacts.fetchAllCompany,
        fetchCompanyRegions: actions.regions.fetchAllCompany,
        fetchCompanyAlerts: actions.alerts.fetchAllCompany,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(alertsContainer);