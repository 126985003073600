import Clone from '../utils/clone';
import constants from '../constants';
const initialState = constants.initialStates.alerts;
const type = constants.actions.alerts;

const removeAlertsForContact = (alerts, contactId) => {
    return Object.values(alerts).reduce((acc, c) => {
        if (c.contacId === contactId) {
            return acc;
        }
        return { ...acc, [`${c.contactId}_${c.regionId}`]: c }
    }, {});
}

export default (state = initialState, action) => {
    let newState = Clone(state);
    switch (action.type) {

        case type.FETCH_ALL: {
            newState.pending.fetchAll = true;
            return newState;
        }

        case type.FETCH_ALL_SUCCESS: {
            newState.list = action.data;
            newState.pending.fetchAll = false;
            newState.nextFetchAll = Date.now() + parseInt(process.env.REFETCH_EVERY);
            return newState;
        }

        case type.CREATE: {
            newState.pending.create = true;
            return newState;
        }
        case type.CREATE_SUCCESS: {
            newState.pending.create = false;
            return newState;
        }
        case type.CREATE_FAILURE: {
            newState.pending.create = false;
            newState.errors.create = action.message || "Could not create alert";
            return newState;
        }

        case type.UPDATE: {
            newState.pending.update = true;
            return newState;
        }
        case type.UPDATE_SUCCESS: {
            if (action.companyId) {
                newState.company[action.companyId][action.alertId] = { ...newState.company[action.companyId][action.alertId], ...action.data };
            } else {
                newState.list[action.alertId] = {...newState.list[action.alertId], ...action.data};
            }
            newState.pending.update = false;
            return newState;
        }
        case type.UPDATE_FAILURE: {
            newState.pending.update = false;
            newState.errors.update = action.message || "Could not update alert";
            return newState;
        }

        case type.DELETE: {
            newState.pending.delete = true;
            return newState;
        }
        case type.DELETE_SUCCESS: {
            if (action.companyId) {
                delete newState.company[action.companyId][action.alertId];
            } else {
                delete newState.list[action.alertId];
            }
            newState.pending.delete = false;
            return newState;
        }


        case type.FETCH_ALL_COMPANY: {
            newState.pending.companyFetch[action.companyId] = true;
            return newState;
        }
        case type.FETCH_ALL_COMPANY_SUCCESS: {
            newState.pending.companyFetch[action.companyId] = false;
            newState.company[action.companyId] = action.data;
            newState.nextCompanyFetchAll[action.companyId] = Date.now() + parseInt(process.env.REFETCH_EVERY);
            return newState;
        }

        // reset the state on logout
        case constants.actions.user.LOGOUT: {
            return initialState;
        }

        // On deletion of a contact, remove it's alerts
        case constants.actions.contacts.DELETE_SUCCESS: {
            if (newState.company[action.companyId]) {
                newState.company[action.companyId] = removeAlertsForContact(newState.company[action.companyId], action.contactId);
            }
            if (newState.list) {
                newState.list = removeAlertsForContact(newState.list, action.contactId);
            }
            return newState;
        }
        
        default:
            return newState;
    }
}