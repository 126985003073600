const getApiHost = () => {
    let selectedPath = localStorage.getItem('API_HOST')
    if (selectedPath) {
        return selectedPath;
    }
    return process.env.GLOBAL_API_PATH;
};

const defaultHeaders = () => ({
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem(process.env.localStorageKey) || `{}`).accessToken}`,
    'Content-Type': 'application/json'
});

let isRefreshing = false;

const request = {
    raw: async (requestPath, headers, method, body, refreshAttempt) => {
        const path = `${getApiHost()}${requestPath}`
        const response = await fetch( path, { mode: "cors", credentials: 'include', headers, method, body } ).catch(e => ({ status: 'offline', message: 'Server did not respond.' }));
        if (response.status === 'failure') return response;

        if (response.status === 401) {
            if (refreshAttempt) {
                return response.json();
            }
            if (isRefreshing) {
                // there is already a refresh happening.. so try the request again in 250ms
                return new Promise(resolve => setTimeout(resolve, 250)).then(async () => {
                    return await request.raw(requestPath, headers['Authorization'] ? defaultHeaders() : headers, method, body, true);
                });
            } else if (await request.refresh()) {
                return await request.raw(requestPath, headers['Authorization'] ? defaultHeaders() : headers, method, body, true);
            }
        }
        return response.json();
    },
    get: async (path) => await request.raw(path, defaultHeaders(), 'GET'),
    put: async (path, body = {}) => await request.raw(path, defaultHeaders(), 'PUT', JSON.stringify(body)),
    patch: async (path, body = {}) => await request.raw(path, defaultHeaders(), 'PATCH', JSON.stringify(body)),
    delete: async (path, body = {}) => await request.raw(path, defaultHeaders(), 'DELETE', JSON.stringify(body)),
    post: async (path, body = {}) => await request.raw(path, defaultHeaders(), 'POST', JSON.stringify(body)),
    refresh: async () => {
        isRefreshing = true;
        const response = await request.raw(`/auth/refresh`, defaultHeaders(), 'POST', JSON.stringify({ refreshToken: JSON.parse(localStorage.getItem(process.env.localStorageKey) || '{}').refreshToken }));
        if (response.status === 'success') {
            // sucessfully refreshed the token
            const result = response;
            localStorage.setItem(`${process.env.localStorageKey}`, JSON.stringify(result.data));
            return true;
        } else {
            localStorage.clear();
            window.location.reload();
        }
        return false;
    }
};

export default request;