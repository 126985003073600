import { call, put, all, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import actions from '../actions';
import constants from '../constants';
const type = constants.actions.regions;

const fetchAll = (api) => function* ({ }) {
    const result = yield api.regions.fetchAll();
    if (result.status === 'success') {
        yield put(actions.regions.fetchAllSuccess(result.data));
    } else {
        yield put(actions.regions.fetchAllFailed(result.message));
    }
}


const fetchAllCompany = (api) => function* ({ companyId }) {
    const result =  yield api.companies.fetchRegions(companyId);
    if (result.status === 'success') {
        return yield put(actions.regions.fetchAllCompanySuccess(result.data, companyId));
    }
    yield put(actions.regions.fetchAllCompanyFailed(result.message));
}

export default function* registerSagas(api) {
    yield fork(takeLatest, type.FETCH_ALL, fetchAll(api));
    yield fork(takeLatest, type.FETCH_ALL_COMPANY, fetchAllCompany(api));
}