import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import autobind from 'class-autobind';
import matchSorter from 'match-sorter';
import actions from '../../actions';
import selectors from '../../selectors';
import Menu from '../../components/user/menu';
import AdminMenu from '../admin/menu';

class menuContainer extends React.Component {

    constructor(props) {
        super(props);

        this.searchTimeout = null;

        this.state = {
            searchValue: "",
            isSearching: true,
            searchResults: null,
            mobileMenu: false,
        }
        autobind(this);
    }
    
    toggleMobileMenu() {
        this.setState({
            mobileMenu: !this.state.mobileMenu
        });
    }

    toggleResults() {
        this.setState({
            isSeaching: !this.state.isSearching
        });
    }

    updateSearch(evt) {
        this.setState({
            searchValue: evt.target.value,
            isSeaching: true,
        }, () => {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(this.getSearchResults, 250);
        });
    }

    getSearchResults() {
        const { contacts, isAdmin, companies } = this.props;

        let searchObjects = Object.keys(contacts).map(contactId => ({ ...contacts[contactId], isContact: true }));
        if (isAdmin) {
            searchObjects = [ 
                                ...searchObjects, 
                                ...Object.keys(companies).map(companyId => ({ ...companies[companyId], isCompany: true }))
                            ];
        }
        // search contacts
        this.setState({
            searchResults: this.state.searchValue === "" ? null : matchSorter(searchObjects, this.state.searchValue, {keys: ['name', 'email', 'mobile']})
        })
    }

    onSearchKeyUp(evt) {
        if (evt.which === 13) {
            this.props.history.push(`/contact/${btoa(this.state.searchResults[0].id)}`);
        }
    }

    componentWillMount() {
        const {
            isFetchingContacts, contacts, fetchContacts, readyToFetchContactsAgain,
            isFetchingCompanies, companies, fetchCompanies,
        } = this.props;

        // fetch contacts
        if ((!contacts || readyToFetchContactsAgain) && !isFetchingContacts) {
            fetchContacts();
        }

        // Fetch Companies
        if ((!companies) && !isFetchingCompanies) {
            fetchCompanies();
        }
    }

    render() {
        const { page, history, isAdmin } = this.props;
        if (isAdmin) {
            return <AdminMenu page={page} history={history} />;
        }
        return <Menu {...this.props} toggleMobileMenu={this.toggleMobileMenu} mobileMenu={this.state.mobileMenu} data={this.state} updateSearch={this.updateSearch} toggleResults={this.toggleResults} onSearchKeyUp={this.onSearchKeyUp} />;
    }
}

menuContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    return {
        contacts: selectors.contacts.getAll(state),
        isFetchingContacts: selectors.contacts.isFetchingAll(state),
        readyToFetchContactsAgain: selectors.contacts.readyToFetchAgain(state),
        companies: selectors.companies.getAll(state),
        isFetchingCompanies: selectors.companies.isFetchingAll(state),
        isAdmin: selectors.user.isAdmin(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchContacts: actions.contacts.fetchAll,
        fetchCompanies: actions.companies.fetchAll,
        logout: actions.user.logout
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(menuContainer);