export default {
    // Maximum number of records to fetch per request
    fetchLimit: 50,

    // number of minutes per square on the scheduler UI
    scheduleBlockTime: 60,

    // Pre-defined schedules
    scheduleTypes: {
        'none': {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
        },
        '9am to 5pm Mon-Fri': {
            sunday: [],
            monday: [{start: 540, end: 1020}],
            tuesday: [{start: 540, end: 1020}],
            wednesday: [{start: 540, end: 1020}],
            thursday: [{start: 540, end: 1020}],
            friday: [{start: 540, end: 1020}],
            saturday: [],
        },
        '3pm to 11pm Mon-Fri': {
            sunday: [],
            monday: [{start: 900, end: 1380}],
            tuesday: [{start: 900, end: 1380}],
            wednesday: [{start: 900, end: 1380}],
            thursday: [{start: 900, end: 1380}],
            friday: [{start: 900, end: 1380}],
            saturday: [],
        },
        '9pm to 7am Mon-Sat': {
            sunday: [],
            monday: [{start: 1260, end: 1440}],
            tuesday: [{start: 0, end: 420}, {start: 1260, end: 1440}],
            wednesday: [{start: 0, end: 420}, {start: 1260, end: 1440}],
            thursday: [{start: 0, end: 420}, {start: 1260, end: 1440}],
            friday: [{start: 0, end: 420}, {start: 1260, end: 1440}],
            saturday: [{start: 0, end: 420}],
        },
        '6am to 6pm Mon-Fri': {
            sunday: [],
            monday: [{start: 360, end: 1080}],
            tuesday: [{start: 360, end: 1080}],
            wednesday: [{start: 360, end: 1080}],
            thursday: [{start: 360, end: 1080}],
            friday: [{start: 360, end: 1080}],
            saturday: [],
        },
        '6pm to 6am Mon-Sat': {
            sunday: [],
            monday: [{start: 1080, end: 1440}],
            tuesday: [{start: 0, end: 360}, {start: 1080, end: 1440}],
            wednesday: [{start: 0, end: 360}, {start: 1080, end: 1440}],
            thursday: [{start: 0, end: 360}, {start: 1080, end: 1440}],
            friday: [{start: 0, end: 360}, {start: 1080, end: 1440}],
            saturday: [{start: 0, end: 360}, ],
        },
    }
}