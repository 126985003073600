import Clone from '../utils/clone';
import constants from '../constants';
const initialState = constants.initialStates.companies;
const type = constants.actions.companies;

export default (state = initialState, action) => {
    let newState = Clone(state);
    switch (action.type) {

        case type.FETCH_ALL: {
            newState.pending.fetchAll = true;
            return newState;
        }

        case type.FETCH_ALL_SUCCESS: {
            newState.list = action.data;
            newState.pending.fetchAll = false;
            newState.nextFetchAll = Date.now() + parseInt(process.env.REFETCH_EVERY);
            return newState;
        }


        // reset the state on logout
        case constants.actions.user.LOGOUT: {
            return initialState;
        }

        default: {
            return newState;
        }
    }
}