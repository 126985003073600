import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faFile, faUserCircle, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/logo.png';
import styles from '../../styles/user';

const menuComponent = (props) => {
    const { page, data, updateSearch, toggleResults, onSearchKeyUp, logout, isAdmin, admin, toggleMobileMenu, mobileMenu } = props;
    return (
        <div className={styles.main.menu}>
            <div className={styles.menu.menu}>
                <div className={styles.menu.menuSearch}>
                    <input type="text" value={data.searchValue} onKeyUp={onSearchKeyUp} onChange={updateSearch} onFocus={toggleResults} onBlur={toggleResults} className={styles.menu.searchInput} placeholder={"search contacts"} />
                    {data.isSearching && data.searchResults !== null ? 
                        <div className={styles.menu.searchResults}>
                            {data.searchResults.map((item, i) => {
                                if (item.isContact) {
                                    return <Link key={i} className={styles.menu.searchResult} to={`/contact/${btoa(item.id)}`}>{item.name}{item.email ? ` (${item.email})` : null}</Link>
                                } else if (item.isCompany && isAdmin) {
                                    return <Link key={i} className={styles.menu.searchResult} to={`/admin/${btoa(item.id)}/contacts`}>{item.name}</Link>
                                }
                            })}
                        </div>
                    : null}
                </div>

                <img src={logo} className={styles.main.logo} />
                
                <div className={styles.menu.mobile} onClick={toggleMobileMenu}>
                    <span className={styles.menu.mobileLine}></span>
                    <span className={styles.menu.mobileLine}></span>
                    <span className={styles.menu.mobileLine}></span>
                </div>

                <div className={styles.menu.mobileLinks} style={{display: mobileMenu ? 'block' : 'none'}}>
                    <Link to="/alert-history" className={`${styles.menu.item} ${(page === undefined || page === '' || page === 'alert-history') && !admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faFile} />Alert History</Link>
                    <Link to="/alerts" className={`${styles.menu.item} ${page && page !== 'alert-history' && page.includes('alert') && !admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faFile} />Alerts</Link>
                    <Link to="/contacts" className={`${styles.menu.item} ${page && page.includes('contact') && !admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faUserAlt} />Contacts</Link>
                    {isAdmin ?
                        <Link to="/admin/companies" className={`${styles.menu.item} ${admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faUserAlt} />Admin</Link>
                    : null}
                    <span role="link" onClick={logout} className={styles.menu.item} style={{float: 'right'}}>Logout</span>
                </div>

                <div className={styles.menu.links}>
                    <Link to="/alert-history" className={`${styles.menu.item} ${(page === undefined || page === '' || page === 'alert-history') && !admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faFile} />Alert History</Link>
                    <Link to="/alerts" className={`${styles.menu.item} ${page && page !== 'alert-history' && page.includes('alert') && !admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faFile} />Alerts</Link>
                    <Link to="/contacts" className={`${styles.menu.item} ${page && page.includes('contact') && !admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faUserAlt} />Contacts</Link>
                    {isAdmin ?
                        <Link to="/admin/companies" className={`${styles.menu.item} ${admin ? styles.menu.activeItem : ''}`}><FontAwesomeIcon icon={faUserAlt} />Admin</Link>
                    : null}
                    <span role="link" onClick={logout} className={styles.menu.item} style={{float: 'right'}}>Logout</span>
                </div>
            </div>
        </div>
    );
}

export default menuComponent;