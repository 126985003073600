const getStateObject = (state) => state.companies;


const getAll = (state) => getStateObject(state).list;

const getById = (state) => (id) => (getStateObject(state).list || {})[id];

const isFetchingAll = (state) => getStateObject(state).pending.fetchAll;

export default {
    getAll,
    getById,
    isFetchingAll,
}