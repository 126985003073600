import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import autobind from 'class-autobind';
import actions from '../../actions';
import selectors from '../../selectors';
import constants from '../../constants';

import AlertHistory from '../../components/user/alert-history';

class alertHistoryContainer extends React.Component {
    constructor(props) {
        super(props);

        autobind(this);
    }

    componentWillMount() {
        const { 
            contacts, isFetchingContacts, fetchContacts,
            regions, isFetchingRegions, fetchRegions,
            alertHistory, isFetchingAlertHistory, fetchAlertHistory, readyToFetchAlertHistoryAgain,
            readyToFetchContactsAgain, readyToFetchRegionsAgain, companyId,
            fetchCompanyContacts, fetchCompanyRegions, fetchCompanyAlertHistory
        } = this.props;

        if ((!contacts || readyToFetchContactsAgain) && !isFetchingContacts) {
            if (companyId) {
                fetchCompanyContacts(companyId);
            } else {
                fetchContacts();
            }
        }

        if ((!regions || readyToFetchRegionsAgain) && !isFetchingRegions) {
            if (companyId) {
                fetchCompanyRegions(companyId);
            } else {
                fetchRegions();
            }
        }
        
        if ((!alertHistory || readyToFetchAlertHistoryAgain) && !isFetchingAlertHistory) {
            if (companyId) {
                fetchCompanyAlertHistory(constants.defined.fetchLimit, companyId);
            } else {
                fetchAlertHistory(constants.defined.fetchLimit);
            }
        }
    }

    fetchMore() {
        const { isFetchingMoreAlertHistory, isFetchingAlertHistory , fetchMoreAlertHistory, alertHistory, companyId, fetchMoreCompanyAlertHistory } = this.props;
        if (!isFetchingMoreAlertHistory && !isFetchingAlertHistory && Object.keys(alertHistory).length > 0) {
            if (companyId) {
                fetchMoreCompanyAlertHistory(constants.defined.fetchLimit, alertHistory[Object.keys(alertHistory).pop()].triggerTimestamp, companyId);
            } else {
                fetchMoreAlertHistory(constants.defined.fetchLimit, alertHistory[Object.keys(alertHistory).pop()].triggerTimestamp);
            }
        }
    }

    render() {
        return (
            <AlertHistory {...this.props} fetchMore={this.fetchMore} />
        );
    }
}

alertHistoryContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    let companyId = ownProps.companyId;
    let regions = companyId ? selectors.regions.getAllForCompany(state)(companyId) : selectors.regions.getAll(state);
    let contacts = companyId ? selectors.contacts.getAllForCompany(state)(companyId) : selectors.contacts.getAll(state);
    return {
        alertHistory: companyId ? selectors.alertHistory.getAllForCompany(state)(companyId) : selectors.alertHistory.getAll(state),
        isFetchingAlertHistory: companyId ? selectors.alertHistory.isFetchingCompany(state)(companyId) : selectors.alertHistory.isFetching(state),
        readyToFetchAlertHistoryAgain: companyId ? selectors.alertHistory.readyToFetchCompanyAgain(state)(companyId) : selectors.alertHistory.readyToFetchAgain(state),

        isMoreToFetch: companyId ? selectors.alertHistory.isMoreToFetchCompany(state)(companyId) : selectors.alertHistory.isMoreToFetch(state),
        isFetchingMoreAlertHistory: companyId ? selectors.alertHistory.isFetchingMoreCompanyAlertHistory(state)(companyId) : selectors.alertHistory.isFetchingMoreAlertHistory(state),

        contacts,
        isFetchingContacts: companyId ? selectors.contacts.isFetchingCompany(state)(companyId) : selectors.contacts.isFetchingAll(state),
        readyToFetchContactsAgain: companyId ? selectors.contacts.readyToFetchCompanyAgain(state)(companyId) : selectors.contacts.readyToFetchAgain(state),

        regions,
        isFetchingRegions: companyId ? selectors.regions.isFetchingCompany(state)(companyId) : selectors.regions.isFetchingAll(state),
        readyToFetchRegionsAgain: companyId ? selectors.regions.readyToFetchCompanyAgain(state)(companyId) : selectors.regions.readyToFetchAgain(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchAlertHistory: actions.alertHistory.fetch,
        fetchMoreAlertHistory: actions.alertHistory.fetchMore,
        fetchContacts: actions.contacts.fetchAll,
        fetchRegions: actions.regions.fetchAll,

        fetchCompanyAlertHistory: actions.alertHistory.fetchCompany,
        fetchMoreCompanyAlertHistory: actions.alertHistory.fetchMoreCompany,
        fetchCompanyContacts: actions.contacts.fetchAllCompany,
        fetchCompanyRegions: actions.regions.fetchAllCompany,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(alertHistoryContainer);