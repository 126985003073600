import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styles from '../../styles/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import constants from '../../constants';
import scheduleString from '../../utils/schedule-string';
import sortObjects from '../../utils/sort-object';

const alertComponent = (props) => {
    const { 
        isFetchingContacts, contacts,
        isFetchingAlerts, alerts,
        isFetchingRegions, regions,
        data, onChange, onSubmit, isNew,
        isCreatingAlert, isUpdatingAlert, onDelete,
        admin, isAdmin, companyId, companies
    } = props;

    const isFetching = isFetchingAlerts || isFetchingAlerts === null || !alerts || 
                       isFetchingContacts || isFetchingContacts === null || !contacts ||
                       isFetchingRegions || isFetchingRegions === null || !regions;

    const sortedCompanyList = companies ? sortObjects(Object.keys(companies).map(id => companies[id]), 'name') : [];
    return (
        <div className={`${styles.main.content} ${styles.main.smallContent}`}>
            {isAdmin && companyId ? <Link to={`/admin/${btoa(companyId)}/alerts`}>Back to Company Alerts</Link> : null}
            <div className={styles.main.header}>
                <div className={styles.main.title}>{isNew ? 'Create ' : 'Edit '}Alert</div>
                <div className={styles.main.subTitle}>create and edit alerts</div>
            </div>

            {isFetching ?
                <p>fetching data..</p>
            :
                <div className="form">
                    {isAdmin && isNew ?
                        <div className={`formItem ${data.errors.includes('companyId') ? 'formItemError' : ''}`}>
                            <label>Company: {data.errors.includes('companyId') ? 'required' : null}</label>
                            <select name="companyId" value={companyId || ''} onChange={(evt) => { props.history.push(`/admin/${btoa(evt.target.value)}/alert`); }}>
                                <option value=""></option>
                                {sortedCompanyList.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
                            </select>
                        </div>
                    : null}
                    <div className={`formItem ${data.errors.includes('regionId') ? 'formItemError' : ''}`}>
                        <label>Region: {data.errors.includes('regionId') ? 'required' : null}</label>
                        <select name="regionId" value={data.regionId} onChange={onChange}>
                            <option value=""></option>
                            {Object.keys(regions).map(r => (<option key={r} value={r}>{regions[r].name}</option>))}
                        </select>
                    </div>
                    <div className={`formItem ${data.errors.includes('contactId') ? 'formItemError' : ''}`}>
                        <label>Contact: {data.errors.includes('contactId') ? 'required' : null}</label>
                        <select name="contactId" value={data.contactId} onChange={onChange}>
                            <option value=""></option>
                            {Object.keys(contacts).map(c => (<option key={c} value={c}>{contacts[c].name}</option>))}
                        </select>
                    </div>
                    {data.contactId ? 
                        <div className="formItem">
                            <label>Alert Methods:</label>
                            <div>
                                <label className={!contacts[data.contactId].mobile ? 'disabledFormItem' : ''}>
                                    <input type="checkbox" name="sms" checked={data.sms} onChange={onChange} disabled={!contacts[data.contactId].mobile} /> SMS
                                    {!contacts[data.contactId].mobile ? <small>- contact has no mobile number</small> : null}
                                </label>
                                <label className={!contacts[data.contactId].email ? 'disabledFormItem' : ''}>
                                    <input type="checkbox" name="email" checked={data.email} onChange={onChange} disabled={!contacts[data.contactId].email} /> Email
                                    {!contacts[data.contactId].email ? <small>- contact has no email</small> : null}
                                </label>
                            </div>
                        </div>
                    : null}
                    {data.contactId ? 
                        <div className="formItem">
                            <label>Contact Alert Schedule:</label>
                            {scheduleString((contacts[data.contactId] || {}).schedule)}
                            <small>To change the schedule edit the contact: <Link to={`${admin ? `/admin/${btoa(companyId)}` : ''}/contact/${btoa(data.contactId)}`}>{contacts[data.contactId].name}</Link></small>
                        </div>
                    : null}
                    {isNew ? 
                        isCreatingAlert ?
                            <span className={`button ${styles.main.submit}`}><FontAwesomeIcon icon={faCircleNotch} spin={true} /> creating alert</span>
                        : 
                            <span className={`button ${styles.main.submit}`} onClick={onSubmit}>Create Alert</span>
                    : 
                        isUpdatingAlert ?
                            <span className={`button ${styles.main.submit}`}><FontAwesomeIcon icon={faCircleNotch} spin={true} /> saving changes</span>
                        :
                            <span className={`button ${styles.main.submit}`} onClick={onSubmit}>Save changes</span>
                    }
                    {!isNew ?
                        <span className={`button clearButton`} onClick={onDelete}>Delete alert</span>
                    : null}
                </div>
            }
        </div>
    )
}

export default withRouter(alertComponent);