import { call, put, all, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import actions from '../actions';
import constants from '../constants';
const type = constants.actions.companies;

const fetchAll = (api) => function* ({ }) {
    const result = yield api.companies.fetchAll();
    if (result.status === 'success') {
        return yield put(actions.companies.fetchAllSuccess(result.data));
    }
    yield put(actions.companies.fetchAllFailed(result.message));
}

export default function* registerSagas(api) {
    yield fork(takeLatest, type.FETCH_ALL, fetchAll(api));
}