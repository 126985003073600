import React from 'react';
import autobind from 'class-autobind';
import ReactTooltip from 'react-tooltip'
import timeOfDay from './time-of-day';
import styles from './scheduler.less';

const mergeNeighbors = (list) => {
    let merged = 0;

    let result = [];
    list.forEach(item => {
        let siblingIndex = result.findIndex(f => item.start >= f.start && item.start <= f.end || item.end >= f.start && item.end <= f.end);
        if (siblingIndex === -1) {
            result.push({
                start: item.start, 
                end: item.end
            })
        } else {
            result[siblingIndex] = {
                start: Math.min(item.start, result[siblingIndex].start),
                end: Math.max(item.end, result[siblingIndex].end),
            }
            merged++;
        }
    });

    if (merged > 0) {
        return mergeNeighbors(result);
    }
    return result;
}

const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
class scheduler extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            days: this.expand(props),
            dragging: false
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            days: this.expand(newProps)
        });
    }

    expand(props) {
        const { schedule, blockTime } = props;
        if (!schedule) {
            return {
                sunday: {},
                monday: {},
                tuesday: {},
                wednesday: {},
                thursday: {},
                friday: {},
                saturday: {},
            }
        }

        const expanded = Object.keys(schedule).reduce((days, day, i) => {
            const s = schedule[day];
            let daySchedules = {};
            s.map(ds => {
                let blocks = (ds.end - ds.start) / blockTime;
                for (let i=0;i<blocks;i++) {
                    daySchedules[timeOfDay(ds.start + (i*blockTime))] = blockTime;
                }
            })
            return { ...days, [day]: daySchedules };
        }, {});

        return expanded;
    }

    flatten() {
        return Object.keys(this.state.days).map(day => {
            let daySchedules = Object.keys(this.state.days[day]);
            if (daySchedules.length > 0) {
                let list = daySchedules.map(s => {
                    let split = s.split(':');
                    let start = parseInt(split[0])*60 + parseInt(split[1]);
                    let end = start + this.state.days[day][s];
                    return { start, end };
                });

                return mergeNeighbors(list);
            }
            return [];
        }).reduce((days, day, index) => {
            return { ...days, [dayNames[index]]: day };
        }, {});
    }

    onMouseDown(name, i) {
        this.setState({
            dragging: true
        }, () => {
            this.setBlock(name, i);
        });
    }

    onMouseEnter(name, i) {
        if (this.state.dragging) {
            this.setBlock(name, i);
        }
    }

    setBlock(name, i) {
        const { blockTime } = this.props;
        let days = this.state.days;
        if (days[name.toLowerCase()][i]) {
            delete days[name.toLowerCase()][i];
        } else {
            days[name.toLowerCase()][i] = blockTime;
        }
        this.setState({
            dragging: true,
            days
        });
    }

    onMouseUp() {
        const { onChange } = this.props;
        this.setState({
            dragging: false
        }, () => {
            onChange(this.flatten())
        });
    }

    toggleDay(name) {
        const { blockTime, onChange } = this.props;
        let days = this.state.days;
        for (let i=0;i<(24*60)/blockTime;i++) {
            let time = timeOfDay(i*blockTime);
            if (days[name.toLowerCase()][time]) {
                delete days[name.toLowerCase()][time];
            } else {
                days[name.toLowerCase()][time] = blockTime;
            }
        }
        this.setState({
            days
        }, () => {
            onChange(this.flatten())
        });
    }

    renderDay(name) {
        const { blockTime } = this.props;
        const blocks = [];
        for (let i=0;i<(24*60)/blockTime;i++) {
            let time = timeOfDay(i*blockTime);
            blocks.push(
                <div key={i} 
                    className={`${styles.block} ${this.state.days[name.toLowerCase()][time] ? styles.active : ''}`} 
                    onMouseDown={() => this.onMouseDown(name, time)} 
                    onMouseUp={() => this.onMouseUp()} 
                    onMouseEnter={() => this.onMouseEnter(name, time)}
                    title={this.state.days[name.toLowerCase()][time] ? 'Active period' : 'Inactive period'}
                    data-tip={this.state.dragging ? null : "Click and drag to set or clear a schedule"}
                >
                    {time}
                </div>
            );
        }
    
        return (
            <div className={styles.day}>
                <div className={styles.dayName} onClick={() => this.toggleDay(name)}>{name}</div>
                {blocks}
            </div>
        );
    }

    render() {
        return (
            <div className={styles.scheduler}>
                {this.renderDay("Sunday")}
                {this.renderDay("Monday")}
                {this.renderDay("Tuesday")}
                {this.renderDay("Wednesday")}
                {this.renderDay("Thursday")}
                {this.renderDay("Friday")}
                {this.renderDay("Saturday")}

                <ReactTooltip />
            </div>
        );
    }
}

export default scheduler;