const prefix = `${process.env.prefix}/`

export default {
    user: {
        FETCH_AUTH: `${prefix}USER/FETCH_AUTH`,

        LOGIN: `${prefix}USER/LOGIN`,
        LOGIN_SUCCESS: `${prefix}USER/LOGIN_SUCCESS`,
        LOGIN_FAILED: `${prefix}USER/LOGIN_FAILED`,

        FETCH: `${prefix}USER/FETCH`,
        FETCH_SUCCESS: `${prefix}USER/FETCH_SUCCESS`,
        FETCH_FAILED: `${prefix}USER/FETCH_FAILED`,

        LOGOUT: `${prefix}USER/LOGOUT`,
    },

    admin: {
        // ??
    },

    contacts: {
        FETCH_ALL: `${prefix}CONTACTS/FETCH_ALL`,
        FETCH_ALL_SUCCESS: `${prefix}CONTACTS/FETCH_ALL_SUCCESS`,
        FETCH_ALL_FAILED: `${prefix}CONTACTS/FETCH_ALL_FAILED`,

        FETCH: `${prefix}CONTACTS/FETCH`,
        FETCH_SUCCESS: `${prefix}CONTACTS/FETCH_SUCCESS`,
        FETCH_FAILED: `${prefix}CONTACTS/FETCH_FAILED`,

        CREATE: `${prefix}CONTACTS/CREATE`,
        CREATE_SUCCESS: `${prefix}CONTACTS/CREATE_SUCCESS`,
        CREATE_FAILED: `${prefix}CONTACTS/CREATE_FAILED`,

        UPDATE: `${prefix}CONTACTS/UPDATE`,
        UPDATE_SUCCESS: `${prefix}CONTACTS/UPDATE_SUCCESS`,
        UPDATE_FAILED: `${prefix}CONTACTS/UPDATE_FAILED`,

        PATCH: `${prefix}CONTACTS/PATCH`,
        PATCH_SUCCESS: `${prefix}CONTACTS/PATCH_SUCCESS`,
        PATCH_FAILED: `${prefix}CONTACTS/PATCH_FAILED`,
        
        DELETE: `${prefix}CONTACTS/DELETE`,
        DELETE_SUCCESS: `${prefix}CONTACTS/DELETE_SUCCESS`,
        DELETE_FAILED: `${prefix}CONTACTS/DELETE_FAILED`,

        FETCH_ALL_COMPANY: `${prefix}CONTACTS/FETCH_ALL_COMPANY`,
        FETCH_ALL_COMPANY_SUCCESS: `${prefix}CONTACTS/FETCH_ALL_COMPANY_SUCCESS`,
        FETCH_ALL_COMPANY_FAILED: `${prefix}CONTACTS/FETCH_ALL_COMPANY_FAILED`,
    },
    alerts: {
        FETCH_ALL: `${prefix}ALERTS/FETCH_ALL`,
        FETCH_ALL_SUCCESS: `${prefix}ALERTS/FETCH_ALL_SUCCESS`,
        FETCH_ALL_FAILED: `${prefix}ALERTS/FETCH_ALL_FAILED`,

        FETCH: `${prefix}ALERTS/FETCH`,
        FETCH_SUCCESS: `${prefix}ALERTS/FETCH_SUCCESS`,
        FETCH_FAILED: `${prefix}ALERTS/FETCH_FAILED`,

        CREATE: `${prefix}ALERTS/CREATE`,
        CREATE_SUCCESS: `${prefix}ALERTS/CREATE_SUCCESS`,
        CREATE_FAILED: `${prefix}ALERTS/CREATE_FAILED`,

        UPDATE: `${prefix}ALERTS/UPDATE`,
        UPDATE_SUCCESS: `${prefix}ALERTS/UPDATE_SUCCESS`,
        UPDATE_FAILED: `${prefix}ALERTS/UPDATE_FAILED`,
        
        DELETE: `${prefix}ALERTS/DELETE`,
        DELETE_SUCCESS: `${prefix}ALERTS/DELETE_SUCCESS`,
        DELETE_FAILED: `${prefix}ALERTS/DELETE_FAILED`,

        FETCH_ALL_COMPANY: `${prefix}ALERTS/FETCH_ALL_COMPANY`,
        FETCH_ALL_COMPANY_SUCCESS: `${prefix}ALERTS/FETCH_ALL_COMPANY_SUCCESS`,
        FETCH_ALL_COMPANY_FAILED: `${prefix}ALERTS/FETCH_ALL_COMPANY_FAILED`,
    },
    regions: {
        FETCH_ALL: `${prefix}REGIONS/FETCH_ALL`,
        FETCH_ALL_SUCCESS: `${prefix}REGIONS/FETCH_ALL_SUCCESS`,
        FETCH_ALL_FAILED: `${prefix}REGIONS/FETCH_ALL_FAILED`,

        FETCH_ALL_COMPANY: `${prefix}REGIONS/FETCH_ALL_COMPANY`,
        FETCH_ALL_COMPANY_SUCCESS: `${prefix}REGIONS/FETCH_ALL_COMPANY_SUCCESS`,
        FETCH_ALL_COMPANY_FAILED: `${prefix}REGIONS/FETCH_ALL_COMPANY_FAILED`,

    },

    alertHistory: {
        FETCH: `${prefix}ALERT_HISTORY/FETCH`,
        FETCH_SUCCESS: `${prefix}ALERT_HISTORY/FETCH_SUCCESS`,
        FETCH_FAILED: `${prefix}ALERT_HISTORY/FETCH_FAILED`,

        FETCH_MORE: `${prefix}ALERT_HISTORY/FETCH_MORE`,
        FETCH_MORE_SUCCESS: `${prefix}ALERT_HISTORY/FETCH_MORE_SUCCESS`,
        FETCH_MORE_FAILED: `${prefix}ALERT_HISTORY/FETCH_MORE_FAILED`,

        FETCH_COMPANY: `${prefix}ALERT_HISTORY/FETCH_COMPANY`,
        FETCH_COMPANY_SUCCESS: `${prefix}ALERT_HISTORY/FETCH_COMPANY_SUCCESS`,
        FETCH_COMPANY_FAILED: `${prefix}ALERT_HISTORY/FETCH_COMPANY_FAILED`,

        FETCH_MORE_COMPANY: `${prefix}ALERT_HISTORY/FETCH_MORE_COMPANY`,
        FETCH_MORE_COMPANY_SUCCESS: `${prefix}ALERT_HISTORY/FETCH_MORE_COMPANY_SUCCESS`,
        FETCH_MORE_COMPANY_FAILED: `${prefix}ALERT_HISTORY/FETCH_MORE_COMPANY_FAILED`,
    },

    companies: {
        FETCH_ALL: `${prefix}COMPANIES/FETCH_ALL`,
        FETCH_ALL_SUCCESS: `${prefix}COMPANIES/FETCH_ALL_SUCCESS`,
        FETCH_ALL_FAILED: `${prefix}COMPANIES/FETCH_ALL_FAILED`,
    }
}