export default (types) => {
    return {
        fetchAll: (companyId) => {
            return {
                type: types.FETCH_ALL,
                companyId
            }
        },
        fetchAllSuccess: (data, companyId) => {
            return {
                type: types.FETCH_ALL_SUCCESS,
                data,
                companyId
            }
        },
        fetchAllFailed: (error) => {
            return {
                type: types.FETCH_ALL_FAILED,
                error
            }
        },

        fetch: () => {
            return {
                type: types.FETCH
            }
        },
        fetchSuccess: (regions) => {
            return {
                type: types.FETCH_SUCCESS,
                regions
            }
        },
        fetchFailure: (message) => {
            return {
                type: types.FETCH_SUCCESS,
                message
            }
        },

        
        fetchAllCompany: (companyId) => {
            return {
                type: types.FETCH_ALL_COMPANY,
                companyId
            }
        },
        fetchAllCompanySuccess: (data, companyId) => {
            return {
                type: types.FETCH_ALL_COMPANY_SUCCESS,
                data,
                companyId
            }
        },
        fetchAllCompanyFailed: (error) => {
            return {
                type: types.FETCH_ALL_COMPANY_FAILED,
                error
            }
        },
    }
}