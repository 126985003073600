import user from './user';
import alerts from './alerts';
import contacts from './contacts';
import regions from './regions';
import companies from './companies';
import alertHistory from './alert-history';

import constants from '../constants';
const types = constants.actions;

export default {
    user: user(types.user),
    alerts: alerts(types.alerts),
    contacts: contacts(types.contacts),
    regions: regions(types.regions),
    companies: companies(types.companies),
    alertHistory: alertHistory(types.alertHistory),
}