import { call, put, all, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import actions from '../actions';
import constants from '../constants';
const type = constants.actions.alertHistory;


const fetch = (api) => function* ({ limit = constants.defined.fetchLimit }) {
    const result = yield api.alertHistory.fetch(limit);
    if (result.status === 'success') {
        yield put(actions.alertHistory.fetchSuccess(result.data));
    } else {
        yield put(actions.alertHistory.fetchFailed(result.message));
    }
}

const fetchMore = (api) => function* ({ limit = constants.defined.fetchLimit, beforeTimestamp }) {
    const result = yield api.alertHistory.fetch(limit, beforeTimestamp);
    if (result.status === 'success') {
        yield put(actions.alertHistory.fetchMoreSuccess(result.data));
    } else {
        yield put(actions.alertHistory.fetchMoreFailed(result.message));
    }
}

const fetchCompany = (api) => function* ({ companyId }) {
    const result =  yield api.companies.fetchAlertHistory(companyId);
    if (result.status === 'success') {
        return yield put(actions.alertHistory.fetchCompanySuccess(result.data, companyId));
    }
    yield put(actions.alertHistory.fetchCompanyFailed(result.message));
}

const fetchMoreCompany = (api) => function* ({ limit = constants.defined.fetchLimit, beforeTimestamp, companyId }) {
    const result = yield api.companies.fetchAlertHistory(companyId, limit, beforeTimestamp);
    if (result.status === 'success') {
        yield put(actions.alertHistory.fetchMoreCompanySuccess(result.data, companyId));
    } else {
        yield put(actions.alertHistory.fetchMoreCompanyFailed(result.message));
    }
}

export default function* registerSagas(api) {
    yield fork(takeLatest, type.FETCH, fetch(api));
    yield fork(takeLatest, type.FETCH_MORE, fetchMore(api));
    yield fork(takeLatest, type.FETCH_COMPANY, fetchCompany(api));
    yield fork(takeLatest, type.FETCH_MORE_COMPANY, fetchMoreCompany(api));
}