exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".alert-history__table___1Nl1Z {\n  display: table;\n  width: 100%;\n}\n.alert-history__table___1Nl1Z .alert-history__row___1ZCws {\n  display: table-row;\n}\n.alert-history__table___1Nl1Z .alert-history__row___1ZCws.alert-history__header___16es6 {\n  color: #999;\n  margin-bottom: 12px;\n}\n.alert-history__table___1Nl1Z .alert-history__row___1ZCws .alert-history__cell___KdrrI {\n  display: table-cell;\n  padding: 6px;\n}\n", "", {"version":3,"sources":["/Users/matt/Desktop/matthewrothery/stormalerts/devtools/deploy/build/webapp/src/styles/user/alert-history.less"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;CACb;AACD;EACE,mBAAmB;CACpB;AACD;EACE,YAAY;EACZ,oBAAoB;CACrB;AACD;EACE,oBAAoB;EACpB,aAAa;CACd","file":"alert-history.less","sourcesContent":[".table {\n  display: table;\n  width: 100%;\n}\n.table .row {\n  display: table-row;\n}\n.table .row.header {\n  color: #999;\n  margin-bottom: 12px;\n}\n.table .row .cell {\n  display: table-cell;\n  padding: 6px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "alert-history__table___1Nl1Z",
	"row": "alert-history__row___1ZCws",
	"header": "alert-history__header___16es6",
	"cell": "alert-history__cell___KdrrI"
};