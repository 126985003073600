import React from 'react';
import styles from '../../styles/user';
import Table from '../user/alert-history/table';

const AdminAlertHistoryComponent = (props) => {
    const { 
        alertHistory, contacts, regions,
        isFetchingAlertHistory, isFetchingContacts, isFetchingRegions, company
    } = props;

    const isFetching = isFetchingAlertHistory || isFetchingContacts || isFetchingRegions || !contacts || !regions || !alertHistory;

    return (
        <div className={styles.main.content}>
            <div className={styles.main.header}>
                <div className={styles.main.actions}></div>
                <div className={styles.main.title}>{company ? `${company.name} ` : null}  Alert History</div>
                <div className={styles.main.subTitle}>logs of all alerts</div>
            </div>
            
            {isFetching ? 
                <p>fetching alert history..</p>
            :
                <Table {...props} />
            }
        </div>
    )
}

export default AdminAlertHistoryComponent;