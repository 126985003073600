import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import auth from '../utils/auth';
import selectors from '../selectors';
import '../styles/global.less';

import User from './user';
import Admin from './admin';
import Login from './login';

class appContainer extends React.Component {
    render() {
        const { isAdmin, history } = this.props;

        // if the user is not authenticated take them to the public site
        const routes = (
            <Router history={history}>
                <Switch>
                    <Route path="/admin/:page?" render={(props) => {
                        const page = props.match && props.match.params && props.match.params.page;
                        let Page = auth.admin(Admin);
                        return <Page page={page} history={history} />
                    }} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/:page?" render={(props) => {
                        const page = props.match && props.match.params && props.match.params.page;
                        if (!page && isAdmin) {
                            return <Redirect to="/admin" />
                        }
                        let Page = auth.user(User);
                        return <Page page={page} history={history} />
                    }} />
                </Switch>
            </Router>
        );
                
        return routes;
    }
}


appContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: selectors.user.isAuthenticated(state),
        isAdmin: selectors.user.isAdmin(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(appContainer);
