export default (types) => {
    return {
        // Fetch all the alerts
        fetch: (limit) => {
            return {
                type: types.FETCH,
                limit
            }
        },
        fetchSuccess: (data) => {
            return {
                type: types.FETCH_SUCCESS,
                data
            }
        },
        fetchFailed: (error) => {
            return {
                type: types.FETCH_FAILED,
                error
            }
        },

        // Fetch addition alerts (paged data)
        fetchMore: (limit, beforeTimestamp, companyId) => {
            return {
                type: types.FETCH_MORE,
                limit,
                beforeTimestamp,
                companyId
            }
        },
        fetchMoreSuccess: (data, companyId) => {
            return {
                type: types.FETCH_MORE_SUCCESS,
                data,
                companyId
            }
        },
        fetchMoreFailed: (error) => {
            return {
                type: types.FETCH_MORE_FAILED,
                error
            }
        },

        
        fetchCompany: (limit, companyId) => {
            return {
                type: types.FETCH_COMPANY,
                limit,
                companyId
            }
        },
        fetchCompanySuccess: (data, companyId) => {
            return {
                type: types.FETCH_COMPANY_SUCCESS,
                data,
                companyId
            }
        },
        fetchCompanyFailed: (error) => {
            return {
                type: types.FETCH_COMPANY_FAILED,
                error
            }
        },
        
        fetchMoreCompany: (limit, beforeTimestamp, companyId) => {
            return {
                type: types.FETCH_MORE_COMPANY,
                limit,
                beforeTimestamp,
                companyId
            }
        },
        fetchMoreCompanySuccess: (data, companyId) => {
            return {
                type: types.FETCH_MORE_COMPANY_SUCCESS,
                data,
                companyId
            }
        },
        fetchMoreCompanyFailed: (error) => {
            return {
                type: types.FETCH_MORE_COMPANY_FAILED,
                error
            }
        },
    }
}