const getStateObject = (state) => state.alertHistory;

const getAll = (state) => getStateObject(state).list;

const getAllForCompany = (state) => (companyId) => getStateObject(state).company[companyId];

const isFetching = (state) => getStateObject(state).pending.fetch;

const readyToFetchAgain = (state) => ((getStateObject(state).nextFetch || 0) - Date.now()) < 0;

const isMoreToFetch = state => getStateObject(state).isMoreToFetch;
const isFetchingMoreAlertHistory = state => getStateObject(state).pending.fetchMore;


const isFetchingCompany = state => (companyId) => getStateObject(state).pending.companyFetch[companyId];

const isMoreToFetchCompany = state => companyId => (getStateObject(state).isMoreToFetchCompany || {})[companyId];
const isFetchingMoreCompanyAlertHistory = state => companyId => (getStateObject(state).pending.fetchMoreCompany || {})[companyId];

const readyToFetchCompanyAgain = (state) => companyId => ((getStateObject(state).nextCompanyFetchAll[companyId] || 0) - Date.now()) < 0;

export default {
    getAll,
    isFetching,
    readyToFetchAgain,
    isMoreToFetch,
    isFetchingMoreAlertHistory,
    getAllForCompany,
    isFetchingCompany,
    isMoreToFetchCompany,
    isFetchingMoreCompanyAlertHistory,
    readyToFetchCompanyAgain,
}