import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import auth from '../../utils/auth';
import Menu from './menu';

import AlertHistory from './alert-history';
import Alerts from './alerts';
import Alert from './alert';
import Contacts from './contacts';
import Contact from './contact';
import styles from '../../styles/user';

class userContainer extends React.Component {
    componentWillMount() {
        document.body.className = "userBody";
    }
    render() {
        const { page, history } = this.props;
        return (
            <div className={styles.main.container}>
                <Menu page={page} history={history} />
                <Switch>
                    <Route exact path="/alert-history" component={auth.user(AlertHistory)} />
                    <Route exact path="/alerts" component={auth.user(Alerts)} />
                    <Route exact path="/alert/:id?" component={auth.user(Alert)} />
                    <Route exact path="/contacts" component={auth.user(Contacts)} />
                    <Route exact path="/contact/:id?" component={auth.user(Contact)} />
                    <Route exact path="/" component={auth.user(AlertHistory)} />

                    {/* Redirect all 404's to the dashboard page */}
                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}

userContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(userContainer);