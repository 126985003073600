import React from 'react';
import timeOfDay from './time-of-day';
import styles from './scheduler.less';

export default (schedule) => {
    if (!schedule) return <span className={styles.scheduleString}>24/7 schedule</span>;
    let noneCounter = 0;
    const scheduleString = Object.keys(schedule).map((day, dayIndex) => {
        if (schedule[day].length > 0) {
            let times = schedule[day].map((time, timeIndex) => {
                return <span key={timeIndex}>{timeOfDay(time.start)} - {timeOfDay(time.end)}; </span>
            })
            return <span key={dayIndex} className={styles.scheduleString}>{day}: {times}</span>
        }
        noneCounter++;
        return null;
    });

    if (noneCounter === Object.keys(schedule).length) {
        return <span className={styles.scheduleString}>no schedule</span>;
    }
    return scheduleString;
}