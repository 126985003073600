import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import autobind from 'class-autobind';
import CompaniesComponent from '../../components/admin/companies';
import selectors from '../../selectors';
import actions from '../../actions';
import sortObject from '../../utils/sort-object';

class companiesContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: 'name',
            sortAsc: {
                'name': true
            },
            sortedCompanies: []
        }

        autobind(this);
    }

    componentWillMount() {
        const { companies, readyToFetchCompaniesAgain, fetchAllCompanies, isFetchingCompanies } = this.props;
        if ((!companies || readyToFetchCompaniesAgain) && !isFetchingCompanies) {
            fetchAllCompanies();
        }
    }

    componentDidMount() {
        this.sort('name');
    }

    // sort the contacts
    sort(sortBy) {
        const { companies } = this.props;

        // Sort the companies 
        let sortedCompanies = companies ? sortObject(Object.keys(companies).map(id => companies[id]), sortBy, this.state.sortAsc[sortBy] ? 'ASC' : 'DESC') : [];

        this.setState({
            sortBy,
            sortAsc: { ...this.state.sortAsc, [sortBy]: !this.state.sortAsc[sortBy] },
            sortedCompanies
        });
    }

    render() {
        return (
            <CompaniesComponent {...this.props} sort={this.sort} sortBy={this.state.sortBy} sortAsc={this.state.sortAsc} sortedCompanies={this.state.sortedCompanies} />
        );
    }
}

companiesContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    return {
        companies: selectors.companies.getAll(state),
        isFetchingCompanies: selectors.companies.isFetchingAll(state),
        readyToFetchCompaniesAgain: false,
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchAllCompanies: actions.companies.fetchAll,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(companiesContainer);