import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import actions from '../../actions';
import selectors from '../../selectors';
import Contact from '../../components/user/contact';
import autobind from 'class-autobind';
import { confirmAlert } from 'react-confirm-alert';

const objectFromContact = (contact) => {
    return {
        id: contact ? contact.id : null,
        name: contact ? contact.name : '',
        email: contact ? contact.email : '',
        mobile: contact ? contact.mobile : '',
        timezone: contact ? contact.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone,
        allClear: contact ? contact.allClear || false : false,
        scheduleType: contact ? contact.scheduleType : 'custom',
        schedule: contact ? contact.schedule : null,
        units: contact ? contact.units : 'metric',
        enabled: contact ? contact.enabled : true,
        singleMessage: contact ? contact.singleMessage : true,
    }
}

class contactContainer extends React.Component {
    constructor(props) {
        super(props);
        const { contact } = props;
        
        autobind(this);

        this.state = {
            ...objectFromContact(contact),
            errors: []
        };
    }

    onChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        });
    }

    setSchedule(value) {
        this.setState({
            schedule: value
        });
    }

    onSubmit(evt) {
        const { contact, create, update, companyId } = this.props;
        evt.preventDefault();

        let errors = [];
        if (this.state.name !== null && !this.state.name) {
            errors.push('name');
        }

        if (errors.length > 0) {
            this.setState({
                errors
            });
            return false;
        }

        if (!contact) {
            // Call the create alert saga
            create({
                name: this.state.name, 
                email: this.state.email,
                mobile: this.state.mobile,
                schedule: this.state.schedule,
                timezone: this.state.timezone,
                allClear: this.state.allClear === "true" || this.state.allClear === true ? true : false,
                units: this.state.units,
                enabled: this.state.enabled === "true" || this.state.enabled === true ? true : false,
                singleMessage: this.state.singleMessage === "true" || this.state.singleMessage === true ? true : false,
                companyId
            });
        } else {
            // Call the alert update saga
            update(
                contact.id, 
                {
                    name: this.state.name, 
                    email: this.state.email,
                    mobile: this.state.mobile,
                    schedule: this.state.schedule,
                    timezone: this.state.timezone,
                    allClear: this.state.allClear === "true" || this.state.allClear === true ? true : false,
                    units: this.state.units,
                    enabled: this.state.enabled === "true" || this.state.enabled === true ? true : false,
                    singleMessage: this.state.singleMessage === "true" || this.state.singleMessage === true ? true : false,
                },
                companyId
            );
        }
    }

    onDelete() {
        const { contact, deleteContact, companyId } = this.props;
        confirmAlert({
            title: `Confirm delete contact: ${contact.name}?`,
            message: 'Are you sure you want to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteContact(contact.id, companyId)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    componentWillMount() {
        const {
            isFetchingContacts, contacts, fetchContacts, readyToFetchContactsAgain,
            companyId, fetchCompanyContacts,
            isAdmin, companies, isFetchingCompanies, fetchCompanies
        } = this.props;

        // fetch contacts
        if ((!contacts || readyToFetchContactsAgain) && !isFetchingContacts) {
            if (companyId) {
                fetchCompanyContacts(companyId);
            } else {
                fetchContacts();
            }
        }

        if (isAdmin && (!companies) && !isFetchingCompanies) {
            fetchCompanies();
        }
    }

    render() {
        const { contact } = this.props;
        return (
            <Contact {...this.props} data={this.state} isNew={!contact} onChange={this.onChange} onSubmit={this.onSubmit} setSchedule={this.setSchedule} onDelete={this.onDelete} />
        );
    }
}

contactContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    let companyId = ownProps.companyId;
    const contactId = ownProps.match && ownProps.match.params && ownProps.match.params.id ? atob(ownProps.match.params.id) : ownProps.contactId ? ownProps.contactId : null;
    let contacts = companyId ? selectors.contacts.getAllForCompany(state)(companyId) : selectors.contacts.getAll(state);
    return {
        contact: selectors.contacts.getById(state)(contactId, companyId) || null,

        contacts,
        isFetchingContacts: companyId ? selectors.contacts.isFetchingCompany(state)(companyId) : selectors.contacts.isFetchingAll(state),

        isCreating: selectors.contacts.isCreating(state),
        isUpdating: selectors.contacts.isUpdating(state),

        readyToFetchContactsAgain: companyId ? selectors.contacts.readyToFetchCompanyAgain(state)(companyId) : selectors.contacts.readyToFetchAgain(state),

        companies: selectors.companies.getAll(state),
        isFetchingCompanies: selectors.companies.isFetchingAll(state),
        isAdmin: selectors.user.isAdmin(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchContacts: actions.contacts.fetchAll,
        update: actions.contacts.update,
        create: actions.contacts.create,
        deleteContact: actions.contacts.delete,
        
        fetchCompanyContacts: actions.contacts.fetchAllCompany,
        fetchCompanies: actions.companies.fetchAll,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(contactContainer);