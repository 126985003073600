import Clone from '../utils/clone';
import constants from '../constants';
const initialState = constants.initialStates.alertHistory;
const type = constants.actions.alertHistory;

export default (state = initialState, action) => {
    let newState = Clone(state);
    switch (action.type) {

        case type.FETCH: {
            newState.pending.fetch = true;
            return newState;
        }
        case type.FETCH_SUCCESS: {
            newState.list = action.data;
            newState.pending.fetch = false;
            newState.nextFetch = Date.now() + parseInt(process.env.REFETCH_EVERY);
            newState.isMoreToFetch = Object.keys(action.data).length > 0 && Object.keys(action.data).length % constants.defined.fetchLimit === 0
            return newState;
        }

        case type.FETCH_MORE: {
            newState.pending.fetchMore = true;
            newState.isMoreToFetch = false;
            return newState;
        }
        case type.FETCH_MORE_SUCCESS: {
            newState.list = { ...newState.list, ...action.data };
            newState.pending.fetchMore = false;
            newState.isMoreToFetch = Object.keys(action.data).length > 0 && Object.keys(action.data).length % constants.defined.fetchLimit === 0
            return newState;
        }

        case type.FETCH_COMPANY: {
            newState.pending.companyFetch[action.companyId] = true;
            return newState;
        }

        case type.FETCH_COMPANY_SUCCESS: {
            newState.pending.companyFetch[action.companyId] = false;
            newState.company[action.companyId] = action.data;
            newState.isMoreToFetchCompany[action.companyId] = Object.keys(action.data).length > 0;
            newState.nextCompanyFetchAll[action.companyId] = Date.now() + parseInt(process.env.REFETCH_EVERY);
            return newState;
        }

        case type.FETCH_MORE_COMPANY: {
            newState.pending.fetchMoreCompany[action.companyId] = true;
            return newState;
        }
        case type.FETCH_MORE_COMPANY_SUCCESS: {
            newState.company[action.companyId] = { ...newState.company[action.companyId], ...action.data };
            newState.pending.fetchMoreCompany[action.companyId] = false;
            newState.isMoreToFetchCompany[action.companyId] = Object.keys(action.data).length > 0 && Object.keys(action.data).length % constants.defined.fetchLimit === 0
            return newState;
        }

        // reset the state on logout
        case constants.actions.user.LOGOUT: {
            return initialState;
        }

        default: {
            return newState;
        }
    }
}