const getStateObject = (state) => state.user;

// Checks the users current session auth
// @returns true or false
const isAuthenticated = (state) => {
    // The token exists, therefore we will assume that the user is authenticated
    // If for some reason the user is not authenticated, all server api calls
    // will fail
    // Maybe we should also check some value in the redux state?? - although the state can be modified by the user
    return localStorage.getItem(process.env.localStorageKey) !== null;
}

const getCompany = state => getStateObject(state).company;
const getUser = state => getStateObject(state).data;

const getRole = (state) => {
    let user = getUser(state);
    if (user) {
        return user.isAdmin ? 'admin' : 'user';
    }
    return null;
}

const isAdmin = (state) => (getUser(state) || {}).isAdmin || false;

const getRegisterError = (state) => {
    return getStateObject(state).errors.registerFailed;
}

const getLoginError = (state) => {
    return getStateObject(state).errors.loginFailed;
}

const isFetching = (state) => {
    return getStateObject(state).pending.fetch;
}

const isLoggingIn = (state) => {
    return getStateObject(state).pending.login;
}

const canAddAlert = (state, totalAlerts) => ((getStateObject(state).company || {}).maxAlerts || 0) > totalAlerts;
const canAddContact = (state, totalContacts) => ((getStateObject(state).company || {}).maxContacts || 0) > totalContacts;

export default {
    isAuthenticated,
    isFetching,
    getCompany,
    getUser,
    getRole,
    getRegisterError,
    getLoginError,
    isLoggingIn,
    canAddAlert,
    canAddContact,
    isAdmin,
}