import Clone from '../utils/clone';
import constants from '../constants';
const initialState = constants.initialStates.contacts;
const type = constants.actions.contacts;

export default (state = initialState, action) => {
    let newState = Clone(state);
    switch (action.type) {

        case type.FETCH_ALL: {
            newState.pending.fetchAll = true;
            return newState;
        }
        case type.FETCH_ALL_SUCCESS: {
            newState.list = action.data;
            newState.pending.fetchAll = false;
            newState.nextFetchAll = Date.now() + parseInt(process.env.REFETCH_EVERY);
            return newState;
        }


        case type.CREATE: {
            newState.pending.create = true;
            return newState;
        }
        case type.CREATE_SUCCESS: {
            newState.pending.create = false;
            return newState;
        }

        case type.UPDATE: {
            newState.pending.update = true;
            return newState;
        }
        case type.UPDATE_SUCCESS: {
            if (action.companyId) {
                newState.company[action.companyId][action.contactId] = { ...newState.company[action.companyId][action.contactId], ...action.data };
            } else {
                newState.list[action.contactId] = {...newState.list[action.contactId], ...action.data};
            }
            newState.pending.update = false;
            return newState;
        }

        case type.DELETE: {
            newState.pending.delete = true;
            return newState;
        }
        case type.DELETE_SUCCESS: {
            if (action.companyId) {
                delete newState.company[action.companyId][action.contactId];
            } else {
                delete newState.list[action.contactId];
            }
            newState.pending.delete = false;
            return newState;
        }


        case type.FETCH_ALL_COMPANY: {
            newState.pending.companyFetch[action.companyId] = true;
            return newState;
        }

        case type.FETCH_ALL_COMPANY_SUCCESS: {
            newState.pending.companyFetch[action.companyId] = false;
            newState.company[action.companyId] = action.data;
            newState.nextCompanyFetchAll[action.companyId] = Date.now() + parseInt(process.env.REFETCH_EVERY);
            return newState;
        }

        // reset the state on logout
        case constants.actions.user.LOGOUT: {
            return initialState;
        }

        default:
            return newState;
    }
}