import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobileAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroller';
import Moment from 'react-moment';

const table = (props) => {
    const { alertHistory, contacts, regions, sort, isMoreToFetch, fetchMore } = props;
    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={fetchMore}
            hasMore={isMoreToFetch}
            loader={<div className="loader" key={0}>Loading ...</div>}
        >
            <div className="table">
                <div className="tableHeader">
                    <div className="tableCell">Alert Time</div>
                    <div className="tableCell">Region</div>
                    <div className="tableCell">Storm Duration</div>
                    <div className="tableCell">Contacted</div>
                    <div className="tableCell"></div>
                </div>
                {Object.keys(alertHistory).length > 0 ? 
                    Object.keys(alertHistory).map((alertId, i) => {
                        const alert = alertHistory[alertId];
                        const duration = alert.allClearTimestamp ? `${Math.ceil((alert.allClearTimestamp - alert.triggerTimestamp)/60)} minutes` : 'n/a';
                        return (
                            <div key={i} className="tableRow noCursor">
                                <div className="tableCell"><Moment date={alert.triggerTimestamp*1000} /></div>
                                <div className="tableCell">{(regions[alert.regionId] || {}).name}</div>
                                <div className="tableCell">{duration}</div>
                                <div className="tableCell">{contacts[alert.contactId] ?<Link to={`/contact/${btoa(alert.contactId)}`}>{contacts[alert.contactId].name}</Link> : 'contact deleted'}</div>
                                <div className="tableCell">{alert.sentEmail ? <FontAwesomeIcon icon={faEnvelope} /> : ''}{alert.sentSms ? <FontAwesomeIcon icon={faMobileAlt} /> : ''}</div>
                            </div>
                        )
                    }) 
                : 
                    <div className="tableRow noCursor">
                        <div className="tableCell">No alert history recorded</div>
                        <div className="tableCell"></div>
                        <div className="tableCell"></div>
                        <div className="tableCell"></div>
                        <div className="tableCell"></div>
                    </div>
                }
            </div>
        </InfiniteScroll>
    )
}

table.defaultProps = {
    alertHistory: {},
    sort: true
}

export default table;