import main from './main.less';
import menu from './menu.less';
import alertHistory from './alert-history.less';
import dashboard from './dashboard.less';
import contact from './contact.less';
import './global.less';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default {
    main,
    menu,
    alertHistory,
    dashboard,
    contact,
}