export default (types) => {
    return {
        // Fetch all the alerts
        fetchAll: (companyId) => {
            return {
                type: types.FETCH_ALL,
                companyId
            }
        },
        fetchAllSuccess: (data, companyId) => {
            return {
                type: types.FETCH_ALL_SUCCESS,
                data,
                companyId
            }
        },
        fetchAllFailed: (error) => {
            return {
                type: types.FETCH_ALL_FAILED,
                error
            }
        },

        // Fetch the alerts
        fetch: () => {
            return {
                type: types.FETCH
            }
        },
        fetchSuccess: (data) => {
            return {
                type: types.FETCH_SUCCESS,
                data
            }
        },
        fetchFailed: (error) => {
            return {
                type: types.FETCH_FAILED,
                error
            }
        },


        // Create an alert
        create: (data) => {
            return {
                type: types.CREATE,
                data
            }
        },
        createSuccess: (data) => {
            return {
                type: types.CREATE_SUCCESS,
                data
            }
        },
        createFailed: (error) => {
            return {
                type: types.CREATE_FAILED,
                error
            }
        },


        // Update an individual alert
        update: (alertId, data, companyId) => {
            return {
                type: types.UPDATE,
                alertId,
                data,
                companyId
            }
        },
        updateSuccess: (alertId, data, companyId) => {
            return {
                type: types.UPDATE_SUCCESS,
                alertId,
                data,
                companyId
            }
        },
        updateFailed: (error) => {
            return {
                type: types.UPDATE_FAILED,
                error
            }
        },

        
        // Delete an alert
        delete: (alertId, companyId) => {
            return {
                type: types.DELETE,
                alertId, 
                companyId
            }
        },
        deleteSuccess: (alertId, companyId) => {
            return {
                type: types.DELETE_SUCCESS,
                alertId,
                companyId
            }
        },
        deleteFailed: (error) => {
            return {
                type: types.DELETE_FAILED,
                error
            }
        },

        

        
        fetchAllCompany: (companyId) => {
            return {
                type: types.FETCH_ALL_COMPANY,
                companyId
            }
        },
        fetchAllCompanySuccess: (data, companyId) => {
            return {
                type: types.FETCH_ALL_COMPANY_SUCCESS,
                data,
                companyId
            }
        },
        fetchAllCompanyFailed: (error) => {
            return {
                type: types.FETCH_ALL_COMPANY_FAILED,
                error
            }
        },
    }
}