import user from './user';
import regions from './regions';
import alerts from './alerts';
import contacts from './contacts';
import alertHistory from './alert-history';
import companies from './companies';

export default {
    user,
    regions,
    alerts,
    contacts,
    alertHistory,
    companies,
}