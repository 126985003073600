import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/login-register.less'
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const loginComponent = (props) => {
    const { onChange, onSubmit, username, password, error, isLoggingIn, setApiHost, apiHost } = props;

    document.body.className = "loginBody";
    
    return (
        <div className={styles.container}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img src={logo} />
                </div>
                <div className={styles.title}>Storm Alerts</div>
                <div className={styles.content}>
                    <form className={`form`} onSubmit={onSubmit}>
                        {error ? <span className={"formError"}>{error}</span> : null}
                        <div className={`row`}>
                            <div className={`col`}>
                                <select name="api" value={apiHost} onChange={setApiHost}>
                                    <option value={process.env.GLOBAL_API_PATH}>TOA Global Alerts</option>
                                    <option value={process.env.AUS_API_PATH}>TOA (GPATS) Australian Alerts</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className={`row`}>
                            <div className={`col`}>
                                <input type="text" className={styles.loginInput} name={"username"} value={username} onChange={onChange} placeholder="username or email" />
                            </div>
                        </div>

                        <div className={`row`}>
                            <div className={`col`}>
                                <input type="password" className={styles.loginInput} name={"password"} value={password} onChange={onChange} placeholder="password" />
                            </div>
                        </div>

                        <div className={`row`}>
                            <div className={`col`}>
                                {isLoggingIn ? 
                                    <span className={styles.submit}><FontAwesomeIcon icon={faCircleNotch} spin={true} /></span>
                                :
                                    <button type="submit" onClick={onSubmit} className={styles.submit}>Sign in</button>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default loginComponent;