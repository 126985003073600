import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import autobind from 'class-autobind';
import actions from '../../actions';
import selectors from '../../selectors';
import AdminContacts from '../../components/admin/contacts';
import sortObject from '../../utils/sort-object';


class contactsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: 'name',
            sortAsc: {
                'name': true,
                'email': null,
                'mobile': null,
                'companyName': null,
            },
            sortedContacts: []
        }
        autobind(this);
    }

    componentWillMount() {
        const {
            isFetchingContacts, contacts, fetchContacts,
            isFetchingAlerts, alerts, fetchAlerts,
            readyToFetchContactsAgain, readyToFetchAlertsAgain,
            companyId,
            fetchCompanyAlerts, fetchCompanyContacts
        } = this.props;

        // fetch alerts
        if ((!alerts || readyToFetchAlertsAgain) && !isFetchingAlerts) {
            if (companyId) {
                fetchCompanyAlerts(companyId);
            } else {
                fetchAlerts();
            }
        }

        // fetch contacts
        if ((!contacts || readyToFetchContactsAgain) && !isFetchingContacts) {
            if (companyId) {
                fetchCompanyContacts(companyId);
            } else {
                fetchContacts();
            }
        }
    }

    componentDidMount() {
        this.sort('name');
    }

    // sort the contacts
    sort(sortBy) {
        const { contacts, companies } = this.props;

        let sortedContacts = companies && contacts ? sortObject(
            Object.keys(contacts).map(id => ({ ...contacts[id], companyName: companies[contacts[id].companyId].name })),
            sortBy,
            this.state.sortAsc[sortBy] ? 'ASC' : 'DESC'
        ) : [];
        
        this.setState({
            sortBy,
            sortAsc: { ...this.state.sortAsc, [sortBy]: !this.state.sortAsc[sortBy] },
            sortedContacts
        });
    }

    render() {
        return (
            <AdminContacts {...this.props} sort={this.sort} sortBy={this.state.sortBy} sortAsc={this.state.sortAsc} sortedContacts={this.state.sortedContacts} />
        );
    }
}

contactsContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    let companyId = ownProps.companyId;
    let alerts = companyId ? selectors.alerts.getAllForCompany(state)(companyId) : selectors.alerts.getAll(state);
    let contacts = companyId ? selectors.contacts.getAllForCompany(state)(companyId) : selectors.contacts.getAll(state);
    let companies = selectors.companies.getAll(state);
    let company = companyId ? selectors.companies.getById(state)(companyId) : null;
    return {
        company,
        companies,
        contacts,
        isFetchingContacts: companyId ? selectors.contacts.isFetchingCompany(state)(companyId) : selectors.contacts.isFetchingAll(state),

        alerts,
        isFetchingAlerts: companyId ? selectors.alerts.isFetchingCompany(state)(companyId) : selectors.alerts.isFetchingAll(state),
        
        companies: selectors.companies.getAll(state),
        isFetchingCompanies: selectors.companies.isFetchingAll(state),

        readyToFetchAlertsAgain: companyId ? selectors.alerts.readyToFetchCompanyAgain(state)(companyId) : selectors.alerts.readyToFetchAgain(state),
        readyToFetchContactsAgain: companyId ? selectors.contacts.readyToFetchCompanyAgain(state)(companyId) : selectors.contacts.readyToFetchAgain(state),

        canAddAlert: selectors.user.canAddAlert(state, Object.keys(alerts || {}).length),
        canAddContact: selectors.user.canAddContact(state, Object.keys(contacts || {}).length),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchContacts: actions.contacts.fetchAll,
        fetchAlerts: actions.alerts.fetchAll,
        
        fetchCompanyContacts: actions.contacts.fetchAllCompany,
        fetchCompanyAlerts: actions.alerts.fetchAllCompany,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(contactsContainer);