import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styles from '../../styles/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Scheduler from '../../utils/scheduler';
import constants from '../../constants';
import momentTZ from 'moment-timezone';
import sortObjects from '../../utils/sort-object';

const contactComponent = (props) => {
    const { 
        isFetchingContacts, contacts, data,
        onChange, onSubmit, isNew, setSchedule,
        isCreating, isUpdating, onDelete,
        isAdmin, companies, companyId
    } = props;

    const isFetching = isFetchingContacts || isFetchingContacts === null || !contacts;

    const sortedCompanyList = companies ? sortObjects(Object.keys(companies).map(id => companies[id]), 'name') : [];

    return (
        <div className={`${styles.main.content} ${styles.main.smallContent}`}>
            {isAdmin && companyId ? <Link to={`/admin/${btoa(companyId)}/contacts`}>Back to Company Contacts</Link> : null}
            <div className={styles.main.header}>
                <div className={styles.main.title}>Contact</div>
                <div className={styles.main.subTitle}>create and edit contacts</div>
            </div>

            {isFetching ? 
                <p>fetching data..</p>
            :
                <div className="form">
                    {isAdmin && isNew ?
                        <div className={`formItem ${data.errors.includes('companyId') ? 'formItemError' : ''}`}>
                            <label>Company: {data.errors.includes('companyId') ? 'required' : null}</label>
                            <select name="companyId" value={companyId || ''} onChange={(evt) => { props.history.push(`/admin/${btoa(evt.target.value)}/contact`); }}>
                                <option value=""></option>
                                {sortedCompanyList.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}
                            </select>
                        </div>
                    : null}
                    <div className={`formItem ${data.errors.includes('name') ? 'formItemError' : ''}`}>
                        <label>Name: {data.errors.includes('name') ? 'required' : null}</label>
                        <input type="text" name="name" value={data.name} onChange={onChange} />
                    </div>
                    <div className={`formItem`}>
                        <label>Email:</label>
                        <input type="email" name="email" value={data.email} onChange={onChange} />
                    </div>
                    <div className={`formItem`}>
                        <label>Mobile:</label>
                        <input type="text" name="mobile" value={data.mobile} onChange={onChange} />
                    </div>
                    <div className={`formItem ${data.errors.includes('timezone') ? 'formItemError' : ''}`}>
                        <label>Timezone:</label>
                        <select name="timezone" value={data.timezone || ''} onChange={onChange}>
                            {momentTZ.tz.names().map(tz => <option key={tz} value={tz}>{tz}</option> )}
                        </select>
                    </div>
                    <div className={`formItem`}>
                        <label>Receive All Clear Alerts:</label>
                        <select name="allClear" value={data.allClear || false} onChange={onChange}>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                    <div className={`formItem`}>
                        <label>Alert Units:</label>
                        <select name="units" value={data.units || 'metric'} onChange={onChange}>
                            <option value={'metric'}>metric</option>
                            <option value={'imperial'}>imperial</option>
                        </select>
                    </div>
                    <div className={`formItem`}>
                        <label>Disable All Alerts:</label>
                        <select name="enabled" value={typeof data.enabled === 'undefined' ? true : data.enabled} onChange={onChange}>
                            <option value={true}>No</option>
                            <option value={false}>Yes</option>
                        </select>
                    </div>
                    <div className={`formItem`}>
                        <label>Group Alerts:</label>
                        <select name="singleMessage" value={typeof data.singleMessage === 'undefined' ? true : data.singleMessage} onChange={onChange}>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                    </div>
                    {data.schedule === null ?
                        <div className="formItem">
                            <label>Alert Schedule: </label>
                            Monitor the region with 24/7 Alerts. (<span className={styles.contact.customAlert} onClick={() => setSchedule(constants.defined.scheduleTypes['none'])}>Set custom schedule</span>)
                        </div>
                    :
                        <div className="formItem">
                            <label>Custom Alert Schedule:</label>
                            <ul className={styles.contact.scheduleOptions}>
                                <li>Set: <span onClick={() => setSchedule(null)}>24/7 Schedule</span></li>
                                <li>Set: <span onClick={() => setSchedule(constants.defined.scheduleTypes['9am to 5pm Mon-Fri'])}>9am to 5pm Mon-Fri</span></li>
                                <li>Set: <span onClick={() => setSchedule(constants.defined.scheduleTypes['3pm to 11pm Mon-Fri'])}>3pm to 11pm Mon-Fri</span></li>
                                <li>Set: <span onClick={() => setSchedule(constants.defined.scheduleTypes['9pm to 7am Mon-Sat'])}>9pm to 7am Mon-Sat</span></li>
                                <li>&nbsp; &nbsp; &nbsp; &nbsp;<span onClick={() => setSchedule(constants.defined.scheduleTypes['none'])}>Clear Schedule</span></li>
                            </ul>
                            <Scheduler schedule={data.schedule} blockTime={constants.defined.scheduleBlockTime} onChange={setSchedule} />
                        </div>
                    }

                    {isNew ? 
                        isCreating ?
                            <span className={`button ${styles.main.submit}`}><FontAwesomeIcon icon={faCircleNotch} spin={true} /> creating contact</span>
                        : 
                            <span className={`button ${styles.main.submit}`} onClick={onSubmit}>Create contact</span>
                    : 
                        isUpdating ?
                            <span className={`button ${styles.main.submit}`}><FontAwesomeIcon icon={faCircleNotch} spin={true} /> saving changes</span>
                        :
                            <span className={`button ${styles.main.submit}`} onClick={onSubmit}>Save changes</span>
                    }
                    {!isNew ?
                        <span className={`button clearButton`} onClick={onDelete}>Delete contact</span>
                    : null}
                </div>
            }
        </div>
    )
}

export default withRouter(contactComponent);