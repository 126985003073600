import React, { PropTypes, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Router, Redirect } from 'react-router-dom';
import actions from '../actions';
import selectors from '../selectors';

/**
 * HOC that Handles whether or not the user is allowed to see the page.
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @returns {Component}
 */
const auth = (allowedRoles, redirectTo = '/login')  => {

    const mapStateToProps = (state) => {
        return {
            role: selectors.user.getRole(state),
            user: selectors.user.getUser(state),
            isAuthenticated: selectors.user.isAuthenticated(state),
            isFetchingUser: selectors.user.isFetching(state),
        }
    };
    
    const mapActionsToProps = (dispatch) => {
        return bindActionCreators({
            fetchUserData: actions.user.fetch
        }, dispatch);
    };
    return WrappedComponent => {

        class WithAuth extends Component {
            componentWillMount() {
                const { isAuthenticated, user, fetchUserData, isFetchingUser } = this.props;
                if (allowedRoles.length > 0 && isAuthenticated && !user && (!isFetchingUser || isFetchingUser === null)) {
                    fetchUserData();
                }
            }
            render() {
                const { isAuthenticated, role, isFetchingUser } = this.props;
                if ((isAuthenticated && !role) || isFetchingUser) {
                    return <p>fetching user data</p>
                }
                
                if (isAuthenticated && allowedRoles.includes(role) || (!isAuthenticated && !role && allowedRoles.length === 0) || (!isAuthenticated && role && allowedRoles.length === 0)) {//|| (isAuthenticated && allowedRoles.length === 0)) {
                    // The user has access, load the component
                    return <WrappedComponent {...this.props} />;
                } else {
                    // Take the user to the login page because they are unauthorised
                    return <Redirect to={redirectTo} />;
                }
            }
        };
        return connect(mapStateToProps, mapActionsToProps)(WithAuth);
    };
};

export default {
    none: auth([], '/'),
    user: auth(['user', 'admin']),
    admin: auth(['admin']),
    role: (roles, redirect) => {
        return auth(roles, redirect)
    }
}