export default (types) => {
    return {

        // Login
        login: (data) => {
            return {
                type: types.LOGIN,
                data
            }
        },
        loginSuccess: () => {
            return {
                type: types.LOGIN_SUCCESS,
            }
        },
        loginFailed: (error) => {
            return {
                type: types.LOGIN_FAILED,
                error
            }
        },


        fetch: () => {
            return {
                type: types.FETCH
            }
        },
        fetchSuccess: (data, company) => {
            return {
                type: types.FETCH_SUCCESS,
                data,
                company
            }
        },
        fetchFailed: (error) => {
            return {
                type: types.FETCH_FAILED,
                error
            }
        },

        
        logout: () => {
            return {
                type: types.LOGOUT
            }
        },
    }
}