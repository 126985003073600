import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import autobind from 'class-autobind';
import selctors from '../selectors';
import actions from '../actions';
import selectors from '../selectors';
import LoginComponent from '../components/login';

class loginContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            username: '',
            password: '',
            error: null,
            apiHost: localStorage.getItem('API_HOST') || process.env.GLOBAL_API_PATH
        }
    }

    componentWillMount() {
        document.body.className = "loginBody";
        
        if (!localStorage.getItem('API_HOST')) {
            localStorage.setItem('API_HOST', process.env.GLOBAL_API_PATH);
        }
    }

    onChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value
        });
    }

    onSubmit(evt) {
        evt.preventDefault();
        const { login } = this.props;

        // validate form data
        let error = null;
        if (!this.state.username || this.state.username === "") {
            error = "email cannot be empty"
        }

        if (error) {
            this.setState({
                error,
            });
            return;
        }
        this.setState({
            error: null
        });

        // if valid, submit
        login({ 
            username: this.state.username,
            password: this.state.password,
        })
    }

    setApiHost(evt) {
        this.setState({
            apiHost: evt.target.value
        }, () => {
            localStorage.setItem('API_HOST', this.state.apiHost);
        });
    }

    render() {
        const { loginError } = this.props;

        let error = this.state.error ||  loginError;
        return (
            <LoginComponent 
                {...this.props} 
                error={error} 
                onSubmit={this.onSubmit} 
                onChange={this.onChange} 
                username={this.state.username} 
                password={this.state.password} 
                setApiHost={this.setApiHost}
                apiHost={this.state.apiHost}
            />
        );
    }
}

loginContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    return {
        loginError: selectors.user.getLoginError(state),
        isLoggingIn: selectors.user.isLoggingIn(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        login: actions.user.login
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(loginContainer);
