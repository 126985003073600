import { call, put, all, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import actions from '../actions';
import constants from '../constants';
const type = constants.actions.user;

const login = (api) => function* ({ data }) {
    const result = yield api.user.login(data);
    if (result.status === 'success') {
        // We have received the access token
        localStorage.setItem(`${process.env.localStorageKey}`, JSON.stringify(result.data));
        
        yield put(actions.user.loginSuccess());

        yield put(push('/'));
    } else {
        yield put(actions.user.loginFailed(result.message));
    }
}

const logout = (api) => function* () {
    // const result = yield api.user.logout();
    localStorage.removeItem(`${process.env.localStorageKey}`);
    yield put(push('/login'));
}

const fetch = (api) => function* () {
    const result = yield api.user.fetch();
    if (result.status === 'success') {
        // we have the users data, but now we want the users company data
        const companyResult = yield api.companies.fetch(result.data.companyId);
        if (companyResult.status === 'success') {
            return yield put(actions.user.fetchSuccess(result.data, companyResult.data));
        }
    }

    // fetch failed, log the user out
    yield put(actions.user.logout());
    yield put(actions.user.fetchFailed(result.message));
}


export default function* registerSagas(api) {
    yield fork(takeLatest, type.LOGIN, login(api));
    yield fork(takeLatest, type.LOGOUT, logout(api));
    yield fork(takeLatest, type.FETCH, fetch(api));
}