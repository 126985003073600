import { call, put, all, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { push } from 'react-router-redux';
import actions from '../actions';
import constants from '../constants';
const type = constants.actions.contacts;


const fetchAll = (api) => function* ({ }) {
    const result = yield api.contacts.fetchAll();
    if (result.status === 'success') {
        yield put(actions.contacts.fetchAllSuccess(result.data));
    } else {
        yield put(actions.contacts.fetchAllFailed(result.message));
    }
}

const fetchAllCompany = (api) => function* ({ companyId }) {
    const result =  yield api.companies.fetchContacts(companyId);
    if (result.status === 'success') {
        return yield put(actions.contacts.fetchAllCompanySuccess(result.data, companyId));
    }
    yield put(actions.contacts.fetchAllCompanyFailed(result.message));
}


const fetch = (api) => function* ({ }) {
    const result = yield api.contacts.fetch();
    if (result.status === 'success') {
        yield put(actions.contacts.fetchSuccess(result.data));
    } else {
        yield put(actions.contacts.fetchFailed(result.message));
    }
}

const create = (api) => function* ({ data }) {
    const result = yield api.contacts.create(data);
    if (result.status === 'success') {
        // Successfully created the contact
        // add the returned contact to the state
        yield put(actions.contacts.createSuccess({ ...data, ...result.data }));

        // Refetch all the contacts
        yield put(actions.contacts.fetchAll());

        // Redirect to the contact list
        if (data.companyId) {
            yield put(push(`/admin/${btoa(data.companyId)}/contacts`));
            yield put(actions.contacts.fetchAllCompany(data.companyId));
        } else {
            yield put(actions.contacts.fetchAll());
            yield put(push('/contacts'));
        }
    } else {
        yield put(actions.contacts.createFailed(result.message));
    }
}

const update = (api) => function* ({ contactId, data, companyId }) {
    const result = yield api.contacts.update(contactId, data);
    if (result.status === 'success') {
        // Successfully updated the contact
        yield put(actions.contacts.updateSuccess(contactId, data, companyId));
    } else {
        yield put(actions.contacts.updateFailed(result.message));
    }
}


const patch = (api) => function* ({ contactId, data, companyId }) {
    const result = yield api.contacts.patch(contactId, data);
    if (result.status === 'success') {
        // Successfully updated the contact
        yield put(actions.contacts.updateSuccess(contactId, data, companyId));
    } else {
        yield put(actions.contacts.updateFailed(result.message));
    }
}

const deleteContact = (api) => function* ({ contactId, companyId }) {
    const result = yield api.contacts.delete(contactId);
    if (result.status === 'success') {
        // Successfully deleted the contact
        yield put(actions.contacts.deleteSuccess(contactId, companyId));
        
        // Redirect to the contacts list
        if (companyId) {
            yield put(push(`/admin/${btoa(companyId)}/contacts`));
        } else {
            yield put(push('/contacts'));
        }
    } else {
        yield put(actions.contacts.deleteFailed(result.message));
    }
}


export default function* registerSagas(api) {
    yield fork(takeLatest, type.FETCH_ALL, fetchAll(api));
    yield fork(takeLatest, type.FETCH, fetch(api));
    yield fork(takeLatest, type.CREATE, create(api));
    yield fork(takeLatest, type.UPDATE, update(api));
    yield fork(takeLatest, type.PATCH, patch(api));
    yield fork(takeLatest, type.DELETE, deleteContact(api));
    yield fork(takeLatest, type.FETCH_ALL_COMPANY, fetchAllCompany(api));
}