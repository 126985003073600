exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".contact__scheduleOptions___1ZEMj {\n  list-style: none;\n  padding: 0;\n  margin: 8px 0;\n}\n.contact__scheduleOptions___1ZEMj > li {\n  display: block;\n}\n.contact__scheduleOptions___1ZEMj > li > span {\n  color: #00b0fc;\n  cursor: pointer;\n}\n.contact__scheduleOptions___1ZEMj > li > span:hover {\n  text-decoration: underline;\n}\n.contact__customAlert___1uNqy {\n  color: #00b0fc;\n  cursor: pointer;\n}\n.contact__customAlert___1uNqy:hover {\n  text-decoration: underline;\n}\n", "", {"version":3,"sources":["/Users/matt/Desktop/matthewrothery/stormalerts/devtools/deploy/build/webapp/src/styles/user/contact.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;CACf;AACD;EACE,eAAe;CAChB;AACD;EACE,eAAe;EACf,gBAAgB;CACjB;AACD;EACE,2BAA2B;CAC5B;AACD;EACE,eAAe;EACf,gBAAgB;CACjB;AACD;EACE,2BAA2B;CAC5B","file":"contact.less","sourcesContent":[".scheduleOptions {\n  list-style: none;\n  padding: 0;\n  margin: 8px 0;\n}\n.scheduleOptions > li {\n  display: block;\n}\n.scheduleOptions > li > span {\n  color: #00b0fc;\n  cursor: pointer;\n}\n.scheduleOptions > li > span:hover {\n  text-decoration: underline;\n}\n.customAlert {\n  color: #00b0fc;\n  cursor: pointer;\n}\n.customAlert:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"scheduleOptions": "contact__scheduleOptions___1ZEMj",
	"customAlert": "contact__customAlert___1uNqy"
};