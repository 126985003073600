import request from './request';
import user from './user';
import contacts from './contacts';
import alerts from './alerts';
import companies from './companies';
import regions from './regions';
import alertHistory from './alert-history';

export default {
    user: user(request),
    contacts: contacts(request),
    alerts: alerts(request),
    companies: companies(request),
    regions: regions(request),
    alertHistory: alertHistory(request),

    // fetchAuth: async (code) => (await request.raw(`${serverPath}/is-authenticated`, { "Content-type": "application/json" }, 'POST', JSON.stringify({ code }))).json(), 

    // authenticate: async (code) => (await request.raw(`${serverPath}/oauth`, { "Content-type": "application/json" }, 'POST', JSON.stringify({ code }))).json(),

    // isAuthenticated: () => request.get('/is_authenticated'),
    
    // createGoscriptUser: (data) => request.post('/user/host-user', data),

    // logout: () => request.get('/user/logout'),
}