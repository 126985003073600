import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../styles/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import constants from '../../constants';
import getSortIcon from '../../utils/sort-icon';

const companiesComponent = (props) => {
    const { sort, sortBy, sortAsc, sortedCompanies, isFetchingAll } = props;
    return (
        <div className={`${styles.main.content}`}>
            <div className={styles.main.header}>
                <div className={styles.main.actions}>
                    <Link to="/admin/alert" className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faBell} /></span> Add Alert</Link>
                    <Link to="/admin/contact" className={`button ${styles.main.actionsAction}`}><span className={styles.main.actionIcon}><FontAwesomeIcon icon={faUserAlt} /></span> Add Contact</Link>
                </div>
                <div className={styles.main.title}>StormAlerts Companies</div>
                <div className={styles.main.subTitle}>with access to stormalerts</div>
            </div>

            {isFetchingAll ?
                <p>fetching companies..</p>
            : 
                <div className="table">
                    <div className="tableHeader">
                        <div className="tableCell" onClick={() => sort('name')}>Company Name <FontAwesomeIcon icon={getSortIcon('name', sortBy, sortAsc)} /></div>
                        <div className="tableCell">Alerts</div>
                        <div className="tableCell">Contacts</div>
                        <div className="tableCell">History</div>
                    </div>
                    {sortedCompanies.map((company, i) => {
                        return (
                            <div key={i} className="tableRow">
                                <div className="tableCell">{company.name}</div>
                                <div className="tableCell"><Link to={`/admin/${btoa(company.id)}/alerts`}>Manage Alerts</Link></div>
                                <div className="tableCell"><Link to={`/admin/${btoa(company.id)}/contacts`}>Manage Contacts</Link></div>
                                <div className="tableCell"><Link to={`/admin/${btoa(company.id)}/alert-history`}>View Alert History</Link></div>
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    )
}

export default companiesComponent;