const getStateObject = (state) => state.alerts;


const getAll = (state) => getStateObject(state).list;

const getAllForCompany = (state) => (companyId) => getStateObject(state).company[companyId];

const getById = (state) => (id, companyId) => {
    if (companyId) {
        return (getStateObject(state).company[companyId] || {})[id] || null;
    }
    return getStateObject(state).list && getStateObject(state).list[id] || null;
}
const isFetchingAll = (state) => {
    return getStateObject(state).pending.fetchAll;
}

const isCreatingAlert = (state) => getStateObject(state).pending.create
const isUpdatingAlert = (state) => getStateObject(state).pending.update

const readyToFetchAgain = (state) => ((getStateObject(state).nextFetchAll || 0) - Date.now()) < 0;

const isFetchingCompany = state => (companyId) => (getStateObject(state).pending.companyFetch || {})[companyId];
const readyToFetchCompanyAgain = (state) => companyId => ((getStateObject(state).nextCompanyFetchAll[companyId] || 0) - Date.now()) < 0;

export default {
    getAll,
    getById,
    isFetchingAll,
    isCreatingAlert,
    isUpdatingAlert,
    readyToFetchAgain,
    getAllForCompany,
    isFetchingCompany,
    readyToFetchCompanyAgain,
}