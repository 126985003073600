import registerUserSagas from './user';
import registerContactsSagas from './contacts';
import registerAlertsSagas from './alerts';
import registerCompaniesSagas from './companies';
import registerRegionsSagas from './regions';
import registerAlertHistorySagas from './alert-history';

export const sagas = (api) => function* () {
    yield* registerUserSagas(api);
    yield* registerContactsSagas(api);
    yield* registerAlertsSagas(api);
    yield* registerCompaniesSagas(api);
    yield* registerRegionsSagas(api);
    yield* registerAlertHistorySagas(api);
};