export default (types) => {
    return {
        // Fetch the contacts
        fetchAll: (companyId) => {
            return {
                type: types.FETCH_ALL,
                companyId
            }
        },
        fetchAllSuccess: (data, companyId) => {
            return {
                type: types.FETCH_ALL_SUCCESS,
                data,
                companyId
            }
        },
        fetchAllFailed: (error) => {
            return {
                type: types.FETCH_ALL_FAILED,
                error
            }
        },

        // Fetch the contact
        fetch: (contactId) => {
            return {
                type: types.FETCH,
                contactId
            }
        },
        fetchSuccess: (data) => {
            return {
                type: types.FETCH_SUCCESS,
                data
            }
        },
        fetchFailed: (error) => {
            return {
                type: types.FETCH_FAILED,
                error
            }
        },


        // Create a contact
        create: (data) => {
            return {
                type: types.CREATE,
                data
            }
        },
        createSuccess: (data) => {
            return {
                type: types.CREATE_SUCCESS,
                data
            }
        },
        createFailed: (error) => {
            return {
                type: types.CREATE_FAILED,
                error
            }
        },


        // Update an individual contact
        update: (contactId, data, companyId) => {
            return {
                type: types.UPDATE,
                contactId,
                data,
                companyId
            }
        },
        updateSuccess: (contactId, data, companyId) => {
            return {
                type: types.UPDATE_SUCCESS,
                contactId,
                data,
                companyId
            }
        },
        updateFailed: (error) => {
            return {
                type: types.UPDATE_FAILED,
                error
            }
        },

        
        // Delete an individual contact
        delete: (contactId, companyId) => {
            return {
                type: types.DELETE,
                contactId,
                companyId
            }
        },
        deleteSuccess: (contactId, companyId) => {
            return {
                type: types.DELETE_SUCCESS,
                contactId,
                companyId
            }
        },
        deleteFailed: (error) => {
            return {
                type: types.DELETE_FAILED,
                error
            }
        },

        
        fetchAllCompany: (companyId) => {
            return {
                type: types.FETCH_ALL_COMPANY,
                companyId
            }
        },
        fetchAllCompanySuccess: (data, companyId) => {
            return {
                type: types.FETCH_ALL_COMPANY_SUCCESS,
                data,
                companyId
            }
        },
        fetchAllCompanyFailed: (error) => {
            return {
                type: types.FETCH_ALL_COMPANY_FAILED,
                error
            }
        },
    }
}