import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import actions from '../../actions';
import selectors from '../../selectors';
import Alert from '../../components/user/alert';
import autobind from 'class-autobind';
import { confirmAlert } from 'react-confirm-alert';

class alertContainer extends React.Component {
    constructor(props) {
        super(props);
        const { alert } = props;
        
        autobind(this);

        this.state = {
           regionId: alert ? alert.regionId : '',
           contactId: alert ? alert.contactId : '',
           sms: alert && alert.sms ? true : false,
           email: alert && alert.email? true : false,
           errors: []
        };
    }

    componentWillReceiveProps(newProps) {
        const { alert } = newProps;
        if (alert !== null && (this.props.alert === null || JSON.stringify(alert) !== JSON.stringify(this.props.alert))) {
            this.setState({
                regionId: alert ? alert.regionId : '',
                contactId: alert ? alert.contactId : '',
                sms: alert && alert.sms ? true : false,
                email: alert && alert.email? true : false,
            });
        }
    }

    onChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked || false : evt.target.value,
            errors: this.state.errors.filter(e => e !== evt.target.name)
        });
    }

    onSubmit(evt) {
        const { alert, create, update, companyId } = this.props;
        evt.preventDefault();

        let errors = [];
        if (this.state.contactId !== null && !this.state.contactId) {
            errors.push('contactId');
        }
        if (this.state.regionId !== null && !this.state.regionId) {
            errors.push('regionId');
        }

        if (errors.length > 0) {
            this.setState({
                errors
            });
            return false;
        }

        if (!alert) {
            // Call the create alert saga
            create({
                contactId: this.state.contactId, 
                regionId: this.state.regionId,
                sms: this.state.sms,
                email: this.state.email,
                companyId
            });
        } else {
            // Call the alert update saga
            update(
                alert.id, 
                {
                    oldContactId: alert.contactId,
                    oldRegionId: alert.regionId,
                    contactId: this.state.contactId || alert.contactId, 
                    regionId: this.state.regionId || alert.regionId,
                    sms: this.state.sms,
                    email: this.state.email
                },
                companyId
            );
        }
    }

    onDelete() {
        const { alert, regions, contacts, deleteAlert, companyId } = this.props;
        
        confirmAlert({
            title: `Confirm delete alert for: ${contacts[alert.contactId].name} / ${regions[alert.regionId].name}?`,
            message: 'Are you sure you want to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteAlert(alert.id, companyId)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    componentWillMount() {
        const {
            isFetchingAlerts, alerts, fetchAlerts,
            isFetchingContacts, contacts, fetchContacts,
            isFetchingRegions, regions, fetchRegions,
            readyToFetchAlertsAgain, readyToFetchContactsAgain, readyToFetchRegionsAgain,
            companyId, 
            fetchCompanyAlerts, fetchCompanyContacts, fetchCompanyRegions,
            isAdmin, companies, isFetchingCompanies, fetchCompanies
         } = this.props;

        // fetch alerts
        if ((!alerts || readyToFetchAlertsAgain) && !isFetchingAlerts) {
            if (companyId) {
                fetchCompanyAlerts(companyId);
            } else {
                fetchAlerts();
            }
        }

        // fetch contacts
        if ((!contacts || readyToFetchContactsAgain) && !isFetchingContacts) {
            if (companyId) {
                fetchCompanyContacts(companyId);
            } else {
                fetchContacts();
            }
        }

        // fetch regions
        if ((!regions || readyToFetchRegionsAgain) && !isFetchingRegions) {
            if (companyId) {
                fetchCompanyRegions(companyId);
            } else {
                fetchRegions();
            }
            fetchRegions();
        }

        if (isAdmin && (!companies) && !isFetchingCompanies) {
            fetchCompanies();
        }
    }

    render() {
        const { alert } = this.props;
        return (
            <Alert {...this.props} data={this.state} isNew={!alert} onChange={this.onChange} onSubmit={this.onSubmit} onDelete={this.onDelete} />
        );
    }
}

alertContainer.propTypes = { }

const mapStateToProps = (state, ownProps) => {
    let companyId = ownProps.companyId;
    const alertId = ownProps.match && ownProps.match.params && ownProps.match.params.id ? atob(ownProps.match.params.id) : ownProps.alertId ? ownProps.alertId : null;
    let alerts = companyId ? selectors.alerts.getAllForCompany(state)(companyId) : selectors.alerts.getAll(state);
    let contacts = companyId ? selectors.contacts.getAllForCompany(state)(companyId) : selectors.contacts.getAll(state);
    let regions = companyId ? selectors.regions.getAllForCompany(state)(companyId) : selectors.regions.getAll(state);
    return {
        alert: selectors.alerts.getById(state)(alertId, companyId),

        alerts,
        isFetchingAlerts: companyId ? selectors.alerts.isFetchingCompany(state)(companyId) : selectors.alerts.isFetchingAll(state),

        contacts,
        isFetchingContacts: companyId ? selectors.contacts.isFetchingCompany(state)(companyId) : selectors.contacts.isFetchingAll(state),

        regions,
        isFetchingRegions: companyId ? selectors.regions.isFetchingCompany(state)(companyId) : selectors.regions.isFetchingAll(state),

        isCreatingAlert: selectors.alerts.isCreatingAlert(state),
        isUpdatingAlert: selectors.alerts.isUpdatingAlert(state),

        readyToFetchAlertsAgain: companyId ? selectors.alerts.readyToFetchCompanyAgain(state)(companyId) : selectors.alerts.readyToFetchAgain(state),
        readyToFetchContactsAgain: companyId ? selectors.contacts.readyToFetchCompanyAgain(state)(companyId) : selectors.contacts.readyToFetchAgain(state),
        readyToFetchRegionsAgain: companyId ? selectors.regions.readyToFetchCompanyAgain(state)(companyId) : selectors.regions.readyToFetchAgain(state),

        companies: selectors.companies.getAll(state),
        isFetchingCompanies: selectors.companies.isFetchingAll(state),
        isAdmin: selectors.user.isAdmin(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        update: actions.alerts.update,
        create: actions.alerts.create,
        deleteAlert: actions.alerts.delete,
        fetchContacts: actions.contacts.fetchAll,
        fetchAlerts: actions.alerts.fetchAll,
        fetchRegions: actions.regions.fetchAll,

        fetchCompanyContacts: actions.contacts.fetchAllCompany,
        fetchCompanyRegions: actions.regions.fetchAllCompany,
        fetchCompanyAlerts: actions.alerts.fetchAllCompany,
        fetchCompanies: actions.companies.fetchAll,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(alertContainer);