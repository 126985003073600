export default (types) => {
    return {
        // Fetch all the alerts
        fetchAll: () => {
            return {
                type: types.FETCH_ALL
            }
        },
        fetchAllSuccess: (data) => {
            return {
                type: types.FETCH_ALL_SUCCESS,
                data
            }
        },
        fetchAllFailed: (error) => {
            return {
                type: types.FETCH_ALL_FAILED,
                error
            }
        },
    }
}