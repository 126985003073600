import Clone from '../utils/clone';
import constants from '../constants';
const initialState = constants.initialStates.user;
const type = constants.actions.user;

export default (state = initialState, action) => {
    let newState = Clone(state);
    switch (action.type) {

        // We are attempting to validate the users authentication
        case type.FETCH_AUTH: {
            newState.pending.fetchAuth = true;
            return newState;
        }


        case type.REGISTER: {
            newState.pending.register = true;
            newState.errors.registerFailed = null;
            return newState;
        }
        case type.REGISTER_SUCCESS: {
            newState.pending.register = false;
            newState.errors.registerFailed = null;
            return newState;
        }
        case type.REGISTER_FAILED: {
            newState.errors.registerFailed = action.error;
            return newState;
        }


        case type.LOGIN: {
            newState.pending.login = true;
            newState.errors.registerFailed = null;
            return newState;
        }
        case type.LOGIN_SUCCESS: {
            newState.pending.login = false;
            newState.errors.loginFailed = null;
            return newState;
        }
        case type.LOGIN_FAILED: {
            newState.pending.login = false;
            newState.errors.loginFailed = action.error;
            return newState;
        }


        case type.FETCH: {
            newState.pending.fetch = true;
            return newState;
        }
        case type.FETCH_SUCCESS: {
            newState.pending.fetch = false;
            newState.data = action.data;
            newState.company = action.company;
            return newState;
        }

        // reset the state on logout
        case constants.actions.user.LOGOUT: {
            return initialState;
        }

        default:
            return newState;
    }
}