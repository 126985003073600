const getStateObject = (state) => state.regions;


const getAll = (state) => getStateObject(state).list;

const getAllForCompany = (state) => (companyId) => getStateObject(state).company[companyId];

const getById = (state) => (id) => getStateObject(state).list && getStateObject(state).list[id] || getStateObject(state).company[id] || null;

const isFetchingAll = (state) => getStateObject(state).pending.fetchAll;

const readyToFetchAgain = (state) => ((getStateObject(state).nextFetchAll || 0) - Date.now()) < 0;

const isFetchingCompany = state => (companyId) => (getStateObject(state).pending.companyFetch || {})[companyId];

const readyToFetchCompanyAgain = (state) => companyId => ((getStateObject(state).nextCompanyFetchAll[companyId] || 0) - Date.now()) < 0;

export default {
    getAll,
    getById,
    isFetchingAll,
    readyToFetchAgain,
    getAllForCompany,
    isFetchingCompany,
    readyToFetchCompanyAgain,
}