export default {
    user: {
        pending: {
            fetch: null,
        },
        errors: {},
        data: null,
        company: null,
    },
    data: {},




    regions: {
        nextCompanyFetchAll: {},
        pending: {
            fetchAll: null,
            companyFetch: {},
        },
        list: null,
        company: {},
    },

    alerts: {
        nextCompanyFetchAll: {},
        pending: {
            fetchAll: null,
            create: null,
            update: null,
            companyFetch: {},
        },
        list: null,
        company: {},
    },

    contacts: {
        nextCompanyFetchAll: {},
        pending: {
            fetchAll: null,
            companyFetch: {},
        },
        list: null,
        company: {},
    },

    alertHistory: {
        isMoreToFetch: true,
        isMoreToFetchCompany: {},
        nextCompanyFetchAll: {},
        pending: {
            fetch: null,
            companyFetch: {},
            fetchMoreCompany: {},
        },
        list: null,
        company: {},
    },

    // Admin States
    companies: {
        pending: {
            fetchAll: null,
        },
        list: null
    }
}